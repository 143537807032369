import _ from 'lodash';

export function isIE() {
  if (!!(window as any).ActiveXObject || 'ActiveXObject' in window) return true;
  else return false;
}

export function isEdge() {
  return navigator.userAgent.indexOf('Edge') > -1;
}
export function isIEAndLess11() {
  return isIE() && !isEdge();
}

export function jumpLoginPage(language: string) {
  // client_id 和 next 不需要我们传 ，tob account login page 会写死在代码里
  const tobLoginUrl = `/login?lang=${_.toLower(language)}`;
  globalThis.location.href = tobLoginUrl;
}

export function jumpChangePassPage(language: string) {
  const tobLoginUrl = `/changePass?lang=${_.toLower(language)}`;
  globalThis.location.href = tobLoginUrl;
}
