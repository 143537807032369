import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Menu } from 'antd';
import style from '../index.module.scss';
import Store from '../../types/store';
import {
  getKeys,
  getMenuElement,
  getDefaultKeys,
  getMenus,
  MenuItem,
  MenuKeys,
} from './config';
import { IS_MOBILE } from '../../utils/constants';

const SiderMenu = (props: RouteComponentProps) => {
  // account/products改变。 SiderMenu组件重新渲染
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const account = useSelector((state: Store.State) => state.account);
  const products = useSelector((state: Store.State) => state.products);

  // 注意：lang/products改变后，需要更新菜单
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const menus = useMemo(() => getMenus() as MenuItem[], [account, products]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const defaultKeys = useMemo(() => getDefaultKeys(menus), [menus]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const el = useMemo(() => getMenuElement(menus), [menus]);

  const [keys, setKeys] = useState<MenuKeys>(defaultKeys);

  useEffect(() => {
    // 根据路由匹配菜单
    setKeys(getKeys(props.location.pathname, menus));
  }, [props.location, menus]);

  const handleOpenChange = useCallback((openKeys: string[]) => {
    setKeys({ open: openKeys, selected: [] });
  }, []);

  return (
    <Menu
      mode={IS_MOBILE ? 'horizontal' : 'inline'}
      className={style.menu}
      defaultOpenKeys={defaultKeys.open}
      defaultSelectedKeys={defaultKeys.selected}
      selectedKeys={keys.selected}
      openKeys={keys.open}
      // @ts-ignore
      onOpenChange={handleOpenChange}
      forceSubMenuRender
    >
      {el}
    </Menu>
  );
};
export default withRouter(SiderMenu);
