/* eslint-disable i18next/no-literal-string */
import moment from 'moment';
import 'moment-timezone';

import config from '../config';
const { timezone = '' } = config;

export enum FormatTypes {
  second = 'YYYY-MM-DD HH:mm:ss',
  minute = 'YYYY-MM-DD HH:mm',
  date = 'YYYY-MM-DD',
}

export type TimeType = keyof typeof FormatTypes;

const ONE_DAY_MIS = 24 * 60 * 60 * 1000;

const getRegionMoment = (dateStrings: string[]) => {
  if (!dateStrings || !dateStrings.length) {
    return [];
  }
  if (dateStrings.every(date => !date.trim())) {
    return [];
  }

  const [start, end] = dateStrings;
  const startMoment = moment.tz(start, timezone);
  let endMoment = moment.tz(end, timezone);

  if (moment(start, FormatTypes.date, true).isValid()) {
    endMoment = moment.tz(endMoment.valueOf() + ONE_DAY_MIS - 1, timezone);
  }

  console.log(startMoment.valueOf(), startMoment.format());
  console.log(
    endMoment.endOf('minute').valueOf(),
    endMoment.endOf('minute').format()
  );
  return [startMoment, endMoment.endOf('minute')];
};

const getTimestampFromTimeRange = (time_range?: moment.Moment[]): number[] => {
  if (!time_range || !time_range.length) {
    return [];
  }
  const [startMoment, endMoment] = time_range;
  return [
    startMoment && startMoment.valueOf(),
    endMoment && endMoment.valueOf(),
  ];
};

type RangeType = moment.unitOfTime.StartOf | 'yesterday';

const getDateRange: (type: RangeType) => moment.Moment[] = (
  type: RangeType
) => {
  const momentTimestamp = moment().valueOf();
  const momentStart = moment.tz(momentTimestamp, timezone);
  const momentEnd = moment.tz(momentTimestamp, timezone);

  if (type === 'yesterday') {
    const todayStartTimestamp = momentStart.startOf('day').valueOf();
    const yesterdayEndTimestamp = todayStartTimestamp - 1;
    const yesterdayStartTimestamp = todayStartTimestamp - ONE_DAY_MIS;

    return [
      moment.tz(yesterdayStartTimestamp, timezone),
      moment.tz(yesterdayEndTimestamp, timezone),
    ];
  }

  return [
    momentStart.startOf(type as moment.unitOfTime.StartOf),
    momentEnd.endOf(type as moment.unitOfTime.StartOf),
  ];
};

export { getDateRange, getRegionMoment, getTimestampFromTimeRange };
