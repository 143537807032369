export enum NoticeTab {
  promotion = '1',
  system = '2',
}
type NoticeState = {
  promotionCount: number;
  systemCount: number;
  tabKey: NoticeTab;
  promotionRefresh: boolean;
  systemRefresh: boolean;
  systemMounted: boolean; //判断system的tabContent组件是否已经挂载
  promotionMounted: boolean;
};

export const INIT_STATE: NoticeState = {
  promotionCount: 0,
  systemCount: 0,
  tabKey: NoticeTab.promotion,
  promotionRefresh: false,
  systemRefresh: false,
  systemMounted: false,
  promotionMounted: false,
};

export default function reducer(
  state: NoticeState,
  action:
    | { type: 'clearAll' }
    | { type: 'clearPromotionCount' }
    | { type: 'clearSystemCount' }
    | { type: 'decreasePromotionCount'; payload: number }
    | { type: 'decreaseSystemCount'; payload: number }
    | { type: 'increasePromotionCount'; payload: number }
    | { type: 'increaseSystemCount'; payload: number }
    | { type: 'setPromotionCount'; payload: number }
    | { type: 'setSystemCount'; payload: number }
    | { type: 'setTabKey'; payload: string }
    | { type: 'setRefresh'; payload: boolean }
    | { type: 'setPromotionRefresh'; payload: boolean }
    | { type: 'setSystemRefresh'; payload: boolean }
    | { type: 'setSystemMounted'; payload: boolean }
    | { type: 'setPromotionMounted'; payload: boolean }
): NoticeState {
  switch (action.type) {
    case 'clearAll':
      return {
        ...state,
        promotionCount: 0,
        systemCount: 0,
      };
    case 'clearPromotionCount':
      return {
        ...state,
        promotionCount: 0,
      };
    case 'clearSystemCount':
      return {
        ...state,
        systemCount: 0,
      };
    case 'decreasePromotionCount':
      const pcount = state.promotionCount - action.payload;
      return {
        ...state,
        promotionCount: pcount < 0 ? 0 : pcount,
      };
    case 'decreaseSystemCount':
      const scount = state.systemCount - action.payload;
      return {
        ...state,
        systemCount: scount < 0 ? 0 : scount,
      };
    case 'increasePromotionCount':
      return {
        ...state,
        promotionCount: state.promotionCount + action.payload,
      };
    case 'increaseSystemCount':
      return {
        ...state,
        systemCount: state.systemCount + action.payload,
      };
    case 'setPromotionCount':
      return {
        ...state,
        promotionCount: action.payload,
      };
    case 'setSystemCount':
      return {
        ...state,
        systemCount: action.payload,
      };
    case 'setTabKey':
      return {
        ...state,
        tabKey: action.payload as NoticeTab,
      };
    case 'setPromotionRefresh':
      return {
        ...state,
        promotionRefresh: action.payload,
      };
    case 'setSystemRefresh':
      return {
        ...state,
        systemRefresh: action.payload,
      };
    case 'setSystemMounted':
      return {
        ...state,
        systemMounted: action.payload,
      };
    case 'setPromotionMounted':
      return {
        ...state,
        promotionMounted: action.payload,
      };
    default:
      return state;
  }
}
