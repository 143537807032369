/* eslint-disable i18next/no-literal-string */
import i18next, { InitOptions } from 'i18next';
import en from '@merchant-portal/shared/src/locales/en.json';
import th from '@merchant-portal/shared/src/locales/th.json';
import vi from '@merchant-portal/shared/src/locales/vi.json';
import { REGION_LANGUAGE } from './config';

const resources: InitOptions['resources'] = {
  en: {
    translation: en,
  },
  th: {
    translation: th,
  },
  vi: {
    translation: vi,
  },
};

export default (lang: string, callback: (error?: Error) => void) => {
  i18next
    // register custom interpolator
    .use({
      name: 'sprintf',
      type: 'postProcessor',
      process(
        text: string,
        key: string,
        { defaultValue }: { defaultValue?: string }
      ) {
        // call t('%s', your_test) return your_test
        if (defaultValue) return text.replace(/%s/, defaultValue);
        return text;
      },
    })
    .init(
      {
        // lng: !language || !resources[language] ? DEFAULT_LNG : language,
        lng: lang || REGION_LANGUAGE || 'en',
        // debug: isDev,
        resources,
        postProcess: 'sprintf',
      },
      callback
    );
};
