import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Tabs } from 'antd';
import moment from 'moment';
import i18next from 'i18next';
import Chart, { ChartPoint, ChartConfiguration } from 'chart.js';
import { OneDayForMonthItem } from '../../types/dashboard';
import variable from '../../global.scss';
import Store from '../../types/store';
import config from '../../config';
import style from './index.module.scss';

const { TabPane } = Tabs;

type ChartTabProps = {
  data: OneDayForMonthItem[];
};

/**
 * 趋势图配置
 */
const chartOptions: ChartConfiguration = {
  type: 'line',
  options: {
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
            drawBorder: false,
          },
          type: 'time',
          time: { unit: 'day', displayFormats: { day: 'MM-DD' } },
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    tooltips: {
      titleFontSize: 14,
      bodyFontSize: 14,
    },
    // legend hide
    legend: { display: false },
  },
};

/**
 * 填补空缺的日期
 */
function fillDateRange({
  from,
  to,
  data,
}: {
  from: Date;
  to: Date;
  data: OneDayForMonthItem[];
}) {
  let currDay = moment(from).startOf('day');
  const endDay = moment(to).startOf('day');

  let currIndex = 0;
  const result: OneDayForMonthItem[] = [];

  while (!currDay.isAfter(endDay, 'day')) {
    let found = {
      total_amount: '0',
      total_orders: 0,
      date: currDay.format('YYYY-MM-DD'),
    };
    if (data[currIndex]) {
      const item = moment(data[currIndex].date);
      if (item.isSame(currDay, 'day')) {
        found = data[currIndex];
        currIndex++;
      }
    }

    result.push(found);
    currDay = currDay.add('day', 1);
  }
  return result;
}

const ChartTab = (props: ChartTabProps) => {
  const lang = useSelector((state: Store.State) => state.account.language);
  useEffect(() => {
    const sales: ChartPoint[] = [];
    const orders: ChartPoint[] = [];
    const data = fillDateRange({
      from: moment()
        .subtract('day', 29)
        .toDate(),
      to: new Date(),
      data: props.data,
    });

    data.forEach(({ total_orders, total_amount, date: x }) => {
      sales.push({ x, y: total_amount });
      orders.push({ x, y: total_orders });
    });

    // eslint-disable-next-line i18next/no-literal-string
    new Chart('sales-chart', {
      data: {
        datasets: [
          {
            label: i18next.t('airpayms_sales'),
            backgroundColor: 'transparent',
            borderColor: variable.chartLine,
            data: sales,
          },
        ],
      },
      ...chartOptions,
    });

    // eslint-disable-next-line i18next/no-literal-string
    new Chart('orders-chart', {
      data: {
        datasets: [
          {
            label: i18next.t('airpayms_chart_orders'),
            backgroundColor: 'transparent',
            borderColor: variable.chartLine,
            data: orders,
          },
        ],
      },
      ...chartOptions,
    });
  }, [props.data, lang]);

  return (
    <div>
      <h3>{i18next.t('airpayms_payment_overview')}</h3>
      {/* chart js need additional div wrapper to be responsive. DO NOT DELETE NEXT DIV LINE */}
      <div className={config.isShopeePay ? style.shopee_pay : ''}>
        <Tabs defaultActiveKey="1">
          <TabPane tab={i18next.t('airpayms_sales')} key="1" forceRender={true}>
            <canvas
              id="sales-chart"
              style={{ width: '100%', height: '220px' }}
              key="1"
            ></canvas>
          </TabPane>
          <TabPane
            tab={i18next.t('airpayms_chart_orders')}
            key="2"
            forceRender={true}
          >
            <canvas
              id="orders-chart"
              style={{ width: '100%', height: '220px' }}
              key="2"
            ></canvas>
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default ChartTab;
