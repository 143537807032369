/* eslint-disable i18next/no-literal-string */
import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import { message } from 'antd';
import { PaginationProps } from 'antd/lib/pagination';

import Table from './Table';
import UploadFile from './UploadFile';

import { postTransferOperationHistory } from '../../../../utils/network';

import batchTransfer from '../../../../types/batchTransfer';
import { PERMISSIONS } from '../../../../enums';

import style from '../index.module.scss';
import AuthorizationGuard from '../../../../components/AuthorizationGuard';

const initSearchPage: batchTransfer.IPageParams = {
  page_no: 1,
  page_size: 10,
};

const TransferHistory = () => {
  // table state
  const [searchPage, setSearchPage] = useState(initSearchPage);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);

  const fetchTableData = async () => {
    setLoading(true);
    try {
      const data = await postTransferOperationHistory({
        ...searchPage,
      });
      setTableData(data.batch_payout_history);
      setTotal(data.total);
    } catch (e) {
      setTableData([]);
      setTotal(0);
      e.message && message.error(e.message);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (pagination: PaginationProps) => {
    setSearchPage({
      page_no: pagination.current,
      page_size: pagination.pageSize,
    });
  };

  useEffect(() => {
    fetchTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchPage]);

  return (
    <div className={style.tabItem}>
      <AuthorizationGuard code={PERMISSIONS['WALLET.BATCH_TRANSFER.TRANSFER']}>
        <UploadFile onSubmitCallback={fetchTableData} />
      </AuthorizationGuard>
      <AuthorizationGuard
        code={[
          PERMISSIONS['WALLET.BATCH_TRANSFER.TRANSFER'],
          PERMISSIONS['WALLET.BATCH_TRANSFER.BATCH_TRANSFER_LOG.VIEW'],
        ]}
      >
        <Table
          data={tableData}
          searchPage={{
            page_no: searchPage.page_no,
            page_size: searchPage.page_size,
          }}
          loading={loading}
          total={total}
          onPageChange={handlePageChange}
        />
      </AuthorizationGuard>
    </div>
  );
};

export default TransferHistory;
