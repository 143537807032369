"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
//
// ─── Transfer History ─────────────────────────────────────────────────────────────
//
exports.schema = {
    type: 'object',
    properties: {
        start_time: { type: 'integer' },
        end_time: { type: 'integer' },
        transaction_id: { type: 'integer' },
        user_phone_num: { type: 'string' },
        status: { type: 'integer' },
        ex_merchant_ref: { type: 'string' },
        shopeepay_order_id: { type: 'string' },
    },
};
exports.displayColumns = [
    ['create_time', 'airpayms_create_time_table'],
    ['transaction_id', 'airpayms_transfer_history_txn_id'],
    ['move_to_phone', 'airpayms_transfer_history_phone_no'],
    ['transaction_amount', 'airpayms_amount'],
    ['status', 'airpayms_voucher_status'],
    ['status_int', 'airpayms_voucher_status'],
    ['ex_merchant_ref', 'airpayms_head_merchant_order_id'],
    ['shopeepay_order_id', 'shoeepayms_filter_shopeepay_order_id'],
    ['transfer_desc', 'airpayms_transfer_history_description'],
    ['memo', 'airpayms_transfer_log_remark'],
];
