import React, { useRef, useCallback, useMemo } from 'react';
import { Form, Button, Input, Select } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import _ from 'lodash';
import moment from 'moment';
import i18next from 'i18next';
import { FormInstance } from 'antd/lib/form';
import qs from 'qs';

import {
  WalletTransactionType,
  WalletTransactionStatus,
  getWalletTransactionTypeMapText,
  getWalletTransactionStatusMapText,
} from '@merchant-portal/shared/lib/wallet/helper';

import commonStyle from '../../../components/business/searchLine/index.module.css';
import DateSelect from '../../../components/business/searchLine/DateSelect';
import AuthorizationGuard from '../../../components/AuthorizationGuard';

import {
  getDateRange,
  getRegionMoment,
  getTimestampFromTimeRange,
} from '../../../utils/time';
import { enum2SelectList } from '../../../utils';
import { DateType, PERMISSIONS } from '../../../enums';

import style from './index.module.scss';

const { Option } = Select;

const transactionStatusList = enum2SelectList(WalletTransactionStatus);
const transactionTypeList = enum2SelectList(WalletTransactionType);

export type BasicSearch = {
  merchant_id?: string;
  outlet_id?: string;
  time_range?: moment.Moment[];
};

interface SearchLineProps {
  initTimeRange?: moment.Moment[];
  onSearch: Function;
  outerFormRef?: React.RefObject<FormInstance>;
  from?: string;
}

const SearchLine = (props: SearchLineProps) => {
  const localFormRef = useRef<FormInstance>(null);

  const initialValues = useMemo(
    () => ({
      time_range: props.initTimeRange || getDateRange(DateType['Today']),
    }),
    [props.initTimeRange]
  );

  const finalRef = useMemo(() => {
    return props.outerFormRef || localFormRef;
  }, [props.outerFormRef]);

  const onFinish = useCallback(
    (values: any) => {
      props.onSearch(values);
    },
    [props]
  );

  // 手动更新日期组件
  const handleDataRangeChange = useCallback(
    (dates: any, dateStrings: string[]) => {
      (finalRef.current as FormInstance).setFieldsValue({
        time_type: undefined,
      });
      (finalRef.current as FormInstance).setFieldsValue({
        time_range: getRegionMoment(dateStrings),
      });
    },
    [finalRef]
  );

  const reset = useCallback(() => {
    // 重置表单
    (finalRef.current as FormInstance).resetFields();

    //重置数据
    finalRef.current?.validateFields().then(values => {
      props.onSearch(values, true);
    });
  }, [finalRef, props]);

  const handleDownload = () => {
    finalRef.current?.validateFields().then(values => {
      const [start_time, end_time] = getTimestampFromTimeRange(
        values.time_range
      );

      values.start_time = start_time;
      values.end_time = end_time;

      // 删除掉后端不需要的参数
      delete values.time_range;
      // 下载
      window.location.href = `/api/wallet/transactionHistory/download?${qs.stringify(
        _.pickBy(values, _.identity)
      )}`;
    });
  };

  const handleValidNumber = (event: any) => {
    const isNumber = /[\d]/.test(String.fromCharCode(event.charCode));
    if (!isNumber) {
      event.preventDefault();
    }
  };

  const handleInputNumberFormat = (
    key: 'txn_min_amount' | 'txn_max_amount' | 'txn_id'
  ) => {
    const value = finalRef.current?.getFieldValue(key);
    if (value && (Number(value) < 0 || isNaN(Number(value)))) {
      finalRef.current?.setFieldsValue({
        [key]: 0,
      });
    }
  };

  return (
    <div className={`${commonStyle.searchLine} ${style.searchLine}`}>
      <AuthorizationGuard
        code={
          PERMISSIONS['WALLET.TRANSACTION_HISTORY.TRANSACTION_HISTORY.DOWNLOAD']
        }
      >
        <div className={style.download} onClick={handleDownload}>
          <span className={style.downloadText}>
            {i18next.t('airpayms_button_download')}
          </span>
          <DownloadOutlined />
        </div>
      </AuthorizationGuard>
      <Form
        ref={finalRef}
        initialValues={initialValues}
        layout="vertical"
        onFinish={onFinish}
        className={commonStyle.form}
      >
        <div style={{ flexWrap: 'wrap' }}>
          <DateSelect
            type={'date'}
            label={`${i18next.t('airpayms_create_date_filter')}:`}
            onRangeChange={handleDataRangeChange}
            from={props.from}
            limitTimeRange={{ days: 60 }}
          />
          <Form.Item
            label={i18next.t('airpayms_transfer_history_txn_id')}
            name="txn_id"
          >
            <Input
              type={'number'}
              placeholder={`${i18next.t(
                'airpayms_input_box_input'
              )} ${i18next.t('airpayms_transfer_history_txn_id')}`}
              onKeyPressCapture={handleValidNumber}
              onBlur={() => handleInputNumberFormat('txn_id')}
              style={{ width: '200px' }}
            />
          </Form.Item>
          <Form.Item
            label={i18next.t('airpayms_txn_history_txn_type')}
            name="transaction_type"
          >
            <Select
              placeholder={`${i18next.t(
                'airpayms_dropdown_box_select'
              )} ${i18next.t('airpayms_txn_history_txn_type')}`}
              style={{ width: '200px' }}
              allowClear
            >
              {transactionTypeList.map(type => {
                return (
                  <Option value={type.value} key={type.value}>
                    {i18next.t(
                      getWalletTransactionTypeMapText[
                        type.value as WalletTransactionType
                      ]
                    )}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item label={i18next.t('airpayms_amount')} name="txn_min_amount">
            <Input
              type={'number'}
              placeholder={`${i18next.t(
                'airpayms_input_box_input'
              )} ${i18next.t('airpayms_filter_min_amount')}`}
              style={{ width: '180px' }}
              onKeyPressCapture={handleValidNumber}
              onBlur={() => handleInputNumberFormat('txn_min_amount')}
              min={0}
            />
          </Form.Item>
          <Form.Item label="     ">
            <span> - </span>
          </Form.Item>
          <Form.Item label="     " name="txn_max_amount">
            <Input
              type={'number'}
              placeholder={`${i18next.t(
                'airpayms_input_box_input'
              )} ${i18next.t('airpayms_filter_max_amount')}`}
              style={{ width: '180px' }}
              onKeyPressCapture={handleValidNumber}
              onBlur={() => handleInputNumberFormat('txn_max_amount')}
              min={0}
            />
          </Form.Item>
          <Form.Item label={i18next.t('airpayms_voucher_status')} name="status">
            <Select
              placeholder={`${i18next.t(
                'airpayms_dropdown_box_select'
              )} ${i18next.t('airpayms_voucher_status')}`}
              style={{ width: '140px' }}
              allowClear
            >
              {transactionStatusList.map(status => {
                return (
                  <Option value={status.value} key={status.value}>
                    {i18next.t(
                      getWalletTransactionStatusMapText[
                        status.value as WalletTransactionStatus
                      ]
                    )}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label={i18next.t('airpayms_transfer_log_remark')}
            name="memo"
          >
            <Input
              placeholder={`${i18next.t(
                'airpayms_input_box_input'
              )} ${i18next.t('airpayms_transfer_log_remark')}`}
              style={{ width: '400px' }}
            />
          </Form.Item>
          <Form.Item label="    ">
            <Button
              type="primary"
              htmlType="submit"
              style={{ padding: '5px 37px' }}
            >
              {i18next.t('airpayms_search_button')}
            </Button>
          </Form.Item>
          <Form.Item label="    ">
            <Button onClick={reset}>
              {i18next.t('airpayms_reset_button')}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default SearchLine;
