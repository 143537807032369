/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import qs from 'query-string';
import i18next from 'i18next';
import { Table } from 'antd';

import {
  TransferOperationHistoryTableItem,
  displayColumns,
} from '@merchant-portal/shared/lib/wallet/batchTransfer';

import {
  BatchStatus,
  getBatchStatusText,
  getBatchStatusStyle,
} from '@merchant-portal/shared/lib/wallet/helper';

import batchTransfer from '../../../../types/batchTransfer';
import style from '../index.module.scss';
import { iframeDownload, s3RedirectBaseUrl } from '../../../../utils/download';

const { Column } = Table;

type TransferHistoryTableProps = {
  data: TransferOperationHistoryTableItem[];
  searchPage: batchTransfer.IPageParams;
  total: number;
  loading: boolean;
  onPageChange: batchTransfer.onPageChange;
};

const TransferHistoryTable: React.FC<TransferHistoryTableProps> = (
  props: TransferHistoryTableProps
) => {
  const downloadFeedback = (id: string) => {
    window.location.href = `/api/wallet/batchTransfer/download?${qs.stringify({
      batch_task_id: id,
    })}`;
  };

  const downloadFile = (url: string) => {
    window.location.href = url;
  };

  return (
    <div>
      <Table
        dataSource={props.data}
        scroll={{ x: 2000 }}
        pagination={{
          current: props.searchPage.page_no,
          pageSize: props.searchPage.page_size,
          total: props.total,
          showQuickJumper: true,
          showSizeChanger: true,
        }}
        onChange={props.onPageChange}
        loading={props.loading}
        rowKey="id"
      >
        {displayColumns.map(([name, i18Key, width]) => {
          switch (name) {
            case 'file_name':
              return (
                <Column
                  title={i18next.t(i18Key)}
                  key={name}
                  dataIndex={name}
                  width={width}
                  ellipsis
                  render={(test, row: any, index) => {
                    return {
                      children: row.file_url ? (
                        <span
                          onClick={() =>
                            downloadFile(`${s3RedirectBaseUrl}${row.id}`)
                          }
                          className={style.downloadText}
                        >
                          {row.file_name}
                        </span>
                      ) : (
                        <span>{row.file_name}</span>
                      ),
                    };
                  }}
                />
              );
            case 'feedback':
              return (
                <Column
                  title={i18next.t(i18Key)}
                  key={name}
                  dataIndex={name}
                  width={width}
                  render={(test, row: any, index) => {
                    return {
                      children:
                        row.batch_status === BatchStatus.PROCESSING ? (
                          <span>{i18next.t('airpayms_button_download')}</span>
                        ) : (
                          <a onClick={() => downloadFeedback(row.id)}>
                            {i18next.t('airpayms_button_download')}
                          </a>
                        ),
                    };
                  }}
                />
              );
            case 'batch_status':
              return (
                <Column
                  title={i18next.t(i18Key)}
                  key={name}
                  dataIndex={name}
                  width={width}
                  render={(test, row: any, index) => {
                    return {
                      children: (
                        <span
                          style={{
                            color:
                              getBatchStatusStyle[
                                row.batch_status as BatchStatus
                              ],
                          }}
                        >
                          {i18next.t(
                            getBatchStatusText[row.batch_status as BatchStatus]
                          )}
                        </span>
                      ),
                    };
                  }}
                />
              );
            case 'total_succ':
              return (
                <Column
                  title={i18next.t(i18Key)}
                  key={name}
                  dataIndex={name}
                  render={(test, row: any, index) => {
                    return {
                      children: (
                        <span>
                          {/* 当 processing 状态时，后端返回的成功数量不准确，所以先用 - 隐藏 */}
                          {row.batch_status === BatchStatus.PROCESSING
                            ? '-'
                            : row.total_succ}
                        </span>
                      ),
                    };
                  }}
                />
              );
            default:
              return (
                <Column
                  title={i18next.t(i18Key)}
                  dataIndex={name}
                  key={name}
                  width={width}
                />
              );
          }
        })}
      </Table>
    </div>
  );
};

export default TransferHistoryTable;
