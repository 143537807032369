/* eslint-disable i18next/no-literal-string */
import { login, logout, changePwd, changeNotLoginPwd } from '../utils/network';
import Store from '../types/store';
import { ThunkAction } from 'redux-thunk';
import { REGION_LANGUAGE } from '../config';

export const SET_USER_INFO = 'user/SET_BASIC_INFO';

export const SET_LANG = 'SET_LANG';

export const INIT_STATE: IAccount = {
  staff_id: 0,
  username: '',
  avatar: '',
  language: REGION_LANGUAGE as string,

  merchant_id: 0,
  top_merchant_id: 0,
  merchant_name: '',
};

const initState = { ...INIT_STATE };

// =================== init ==================
if (process.env.NODE_ENV !== 'production') {
  // 为了方便测试，读取本地历史
  const accountInfo = globalThis.sessionStorage.getItem('account');
  accountInfo && Object.assign(initState, JSON.parse(accountInfo));
  // initState.permissions = [
  //   'TRANSACTION.PURCHASE.VIEW',
  //   'TRANSACTION.PURCHASE.DOWNLOAD',
  //   'TRANSACTION.PURCHASE.REFUND',
  //   'TRANSACTION.VOUCHER.VIEW',
  //   'TRANSACTION.VOUCHER.DOWNLOAD',
  //   'SETTLEMENT.VIEW',
  //   'SETTLEMENT.DOWNLOAD',
  //   'TAXINVOICE.VIEW',
  //   'TAXINVOICE.DOWNLOAD',
  // ];
} else {
  const accountInfo = globalThis.__ACCOUNT__;
  accountInfo && Object.assign(initState, accountInfo);
}

// =================== sync action ================
export function setStaffInfo(data: IAccount) {
  return {
    type: SET_USER_INFO,
    data,
  };
}

export function setLang(data: string) {
  return {
    type: SET_LANG,
    data,
  };
}

// =================== async action ================

type Actions = ReturnType<typeof setStaffInfo>; // | ReturnType<typeof otherActionCreator>

export function loginThunk(
  ...params: Parameters<typeof login>
): ThunkAction<void, Account, unknown, Actions> {
  return async dispatch => {
    const data = await login(...params);
    const accountInfo = {
      // @ts-ignore
      username: params[0].username,
      ...data,
    };
    dispatch(setStaffInfo(accountInfo));
    if (process.env.NODE_ENV !== 'production') {
      // 为了方便测试， 记住用户信息
      globalThis.sessionStorage.setItem('account', JSON.stringify(accountInfo));
    }
  };
}

export function logoutThunk(): ThunkAction<void, IAccount, unknown, Actions> {
  return async dispatch => {
    await logout();
    // 重置账户信息，跳转到登陆页
    dispatch(setStaffInfo(INIT_STATE));
    if (process.env.NODE_ENV !== 'production') {
      // 为了方便测试， 记住用户信息
      globalThis.sessionStorage.setItem('account', '');
    }
  };
}

export function changePwdThunk(pwdObj: {
  old_password: string;
  new_password: string;
}): ThunkAction<void, unknown, IAccount, Actions> {
  return async dispatch => {
    await changePwd(pwdObj);
    // 重置账户信息，跳转到登陆页
    dispatch(setStaffInfo(INIT_STATE));
    if (process.env.NODE_ENV !== 'production') {
      // 为了方便测试， 记住用户信息
      globalThis.sessionStorage.setItem('account', '');
    }
  };
}

export function changePwdNotLoginThunk(pwdObj: {
  old_password: string;
  new_password: string;
  username: string;
  verify_code?: {
    verify_code_id: string;
    verify_code_value: string;
  };
}): ThunkAction<void, unknown, IAccount, Actions> {
  return async () => {
    await changeNotLoginPwd(pwdObj);
  };
}

// =================== reducer ================

const account = (state: IAccount = initState, action: Actions): IAccount => {
  switch (action.type) {
    case SET_USER_INFO:
      return action.data;
    case SET_LANG:
      return {
        ...state,
        language: (action.data as unknown) as string,
      };
    default:
      return state;
  }
};

export default account;
