"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * mis product 枚举
 */
var Product;
(function (Product) {
    Product[Product["Deal"] = 20] = "Deal";
    Product[Product["C scan B (static)"] = 8] = "C scan B (static)";
    Product[Product["C scan B (dynamic)"] = 11] = "C scan B (dynamic)";
    Product[Product["B scan C"] = 7] = "B scan C";
    Product[Product["Oauth"] = 5] = "Oauth";
    Product[Product["Payment Gateway"] = 3] = "Payment Gateway";
    Product[Product["Auth Direct Payment"] = 9] = "Auth Direct Payment";
    Product[Product["SDK Tokenized Payment"] = 2] = "SDK Tokenized Payment";
    Product[Product["Offline topup"] = 16] = "Offline topup";
    Product[Product["Merchant Wallet Payout"] = 21] = "Merchant Wallet Payout";
})(Product = exports.Product || (exports.Product = {}));
/**
 * 可以退款的 mis product codes
 * B scan C 不允许在 portal 退款。有问题联系 jianjun.zhu
 */
exports.productCodesRefundable = [
    Product['C scan B (static)'],
    Product['C scan B (dynamic)'],
    Product['Payment Gateway'],
    Product['Auth Direct Payment'],
];
var SettleProductType;
(function (SettleProductType) {
    SettleProductType[SettleProductType["Payment Product"] = 2] = "Payment Product";
    SettleProductType[SettleProductType["DEAL"] = 1] = "DEAL";
})(SettleProductType = exports.SettleProductType || (exports.SettleProductType = {}));
var TaxInvoiceStatus;
(function (TaxInvoiceStatus) {
    TaxInvoiceStatus[TaxInvoiceStatus["Successful"] = 3] = "Successful";
    TaxInvoiceStatus[TaxInvoiceStatus["Invalid"] = 4] = "Invalid";
})(TaxInvoiceStatus = exports.TaxInvoiceStatus || (exports.TaxInvoiceStatus = {}));
exports.SettleProductMapText = {
    [SettleProductType.DEAL]: 'airpayms_filter_deal',
    [SettleProductType['Payment Product']]: 'airpayms_filter_payment_product',
};
exports.TaxInvoiceMapText = {
    [TaxInvoiceStatus.Successful]: 'airpayms_status_successful',
    [TaxInvoiceStatus.Invalid]: 'airpayms_status_invalid',
};
var TaxInvoiceDownloadFileType;
(function (TaxInvoiceDownloadFileType) {
    TaxInvoiceDownloadFileType[TaxInvoiceDownloadFileType["CSV"] = 0] = "CSV";
    TaxInvoiceDownloadFileType[TaxInvoiceDownloadFileType["PDF"] = 1] = "PDF";
    TaxInvoiceDownloadFileType[TaxInvoiceDownloadFileType["XML"] = 2] = "XML";
})(TaxInvoiceDownloadFileType = exports.TaxInvoiceDownloadFileType || (exports.TaxInvoiceDownloadFileType = {}));
