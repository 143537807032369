import React from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import i18next from 'i18next';
import Icon, { MoneyCollectOutlined } from '@ant-design/icons';
import { Product } from '@merchant-portal/shared/lib/product';

import DashBoard from '../../pages/dashboard';
import TransactionReport from '../../pages/report/transaction';
import SettlementReport from '../../pages/report/settlement';
import ShopeeDealReport from '../../pages/report/shopeeDeal';
import TaxInvoice from '../../pages/report/taxInvoice';
import Report from '../../pages/report';
import Wallet from '../../pages/wallet';
import TransactionHistory from '../../pages/wallet/transactionHistory';
import BatchTransfer from '../../pages/wallet/batchTransfer';
import ChangePass from '../../pages/changePass';
// import Test from '../../pages/test';
import { ReactComponent as DashboardSVG } from '../../images/ic_dashboard.svg';
import { ReactComponent as ReportSVG } from '../../images/ic_report.svg';

import RouteWithMenus from '../../components/RouteWithMenus';
import store from '../../store';

import { PERMISSIONS } from '../../enums';
import config from '../../config';

const { SubMenu } = Menu;

// 每一个菜单的配置项
export type MenuItem = {
  name: string; // 菜单名称
  icon?: React.ReactElement; // 图标
  to: string; // 对应 <Route path="xxx"/>  <Link to="xxx"/>  <Menu.Item key="xxx">
  subMenus?: MenuItem[];
  component: React.FunctionComponent<{ menus?: MenuItem[] }>;
  default?: boolean; // 是否为父菜单的默认菜单，是否为父菜单的备用路由
  hide?: boolean; // 设置为true，菜单中不展示且不生成对应路由
  hideInMenu?: boolean; // 设置为true，菜单中不展示
};

// 菜单需要在i18初始完成后获取多语言文案，所以封装在方法中，而不是定义菜单
export const getMenus = (): MenuItem[] => {
  const {
    account: { permissions },
    products,
  } = store.getState();

  // FIXME: 整个 getMenu 要做成一个 hook，现在的更新完全依靠调用方
  const isUnauthorized = (list: PERMISSIONS[]) => {
    // 只要拥有其中任意一个 permission 就认为已经被授权
    return (
      !permissions?.length || list.every(item => !permissions.includes(item))
    );
  };

  const menus: MenuItem[] = [
    {
      name: i18next.t('airpayms_dashboard'),
      icon: <Icon component={DashboardSVG} style={{ fontSize: 20 }} />,
      to: '/dashboard',
      component: DashBoard,
      default: true,
    },
    {
      name: i18next.t('airpayms_change_password'),
      to: '/changePass',
      component: ChangePass,
      hideInMenu: true,
    },
    {
      name: i18next.t('airpayms_change_password'),
      to: '/changePassword',
      component: ChangePass,
      hideInMenu: true,
    },
    // {
    //   name: i18next.t('airpayms_report'),
    //   icon: <Icon component={ReportSVG} style={{ fontSize: 20 }} />,
    //   to: '/report',
    //   component: Report,
    //   subMenus: [
    //     {
    //       name: i18next.t('airpayms_download_transaction_report'),
    //       to: '/report/transaction',
    //       component: TransactionReport,
    //       hide: isUnauthorized(
    //         [
    //           PERMISSIONS['TRANSACTION.PURCHASE.VIEW'],
    //           !config.separatedVoucherMenu
    //             ? PERMISSIONS['TRANSACTION.VOUCHER.VIEW']
    //             : undefined,
    //         ].filter(Boolean) as PERMISSIONS[]
    //       ),
    //       default: true,
    //     },
    //     {
    //       name: i18next.t('airpayms_shopee_deal_report'),
    //       to: '/report/deal',
    //       component: ShopeeDealReport,
    //       hide:
    //         isUnauthorized([PERMISSIONS['TRANSACTION.VOUCHER.VIEW']]) ||
    //         !config.separatedVoucherMenu ||
    //         products.every(item => item.id !== Product.Deal),
    //     },
    //     {
    //       name: i18next.t('airpayms_settlement_report'),
    //       to: '/report/settlement',
    //       component: SettlementReport,
    //       hide: isUnauthorized([PERMISSIONS['SETTLEMENT.VIEW']]),
    //     },
    //     {
    //       name: i18next.t('airpayms_tax_invoice'),
    //       to: '/report/tax',
    //       component: TaxInvoice,
    //       hide:
    //         isUnauthorized([PERMISSIONS['TAXINVOICE.VIEW']]) ||
    //         !config.showTaxInvoice,
    //     },
    //   ],
    // },
    {
      name: i18next.t('airpayms_menu1_merchant_wallet'),
      icon: <MoneyCollectOutlined style={{ fontSize: 20 }} />,
      to: '/wallet',
      component: Wallet,
      hide:
        !config.showWallet ||
        products.every(item => item.id !== Product['Merchant Wallet Payout']),
      subMenus: [
        {
          name: i18next.t('airpayms_menu2_txn_history'),
          to: '/wallet/transactionHistory',
          component: TransactionHistory,
          hide: isUnauthorized([
            PERMISSIONS['WALLET.TRANSACTION_HISTORY.WALLET_BALANCE.VIEW'],
            PERMISSIONS['WALLET.TRANSACTION_HISTORY.TRANSACTION_HISTORY.VIEW'],
          ]),
        },
        {
          name: i18next.t('airpayms_menu2_transfer_history'),
          to: '/wallet/batchTransfer',
          component: BatchTransfer,
          hide: isUnauthorized([
            PERMISSIONS['WALLET.BATCH_TRANSFER.BATCH_TRANSFER_LOG.VIEW'],
            PERMISSIONS['WALLET.BATCH_TRANSFER.TRANSFER_HISTORY.VIEW'],
          ]),
        },
      ],
    },
  ];
  menus.forEach(item => {
    if (item.subMenus?.every(item => item.hide)) {
      item.hide = true;
    }
  });
  return menus;
};

export type MenuKeys = {
  open: string[]; // 打开菜单对应的key
  selected: string[]; // 所选菜单对应的key
};

// 获得菜单对应的默认打开和选择的key
export const getDefaultKeys = (
  menus: MenuItem[],
  initKeys: MenuKeys = { open: [], selected: [] }
): MenuKeys => {
  if (!menus || !menus.length) {
    return initKeys;
  }

  // 找到默认展示的菜单
  const defaultMenu = menus.find(menu => menu.default);

  if (!defaultMenu) {
    return initKeys;
  }

  // 默认菜单没有子菜单，只设置selected
  if (!defaultMenu.subMenus) {
    return {
      open: initKeys.open,
      selected: [...initKeys.selected, defaultMenu.to],
    };
  }

  // 默认菜单有子菜单，递归查找子菜单中的默认菜单
  return getDefaultKeys(defaultMenu.subMenus, {
    open: [...initKeys.open, defaultMenu.to],
    selected: initKeys.selected,
  });
};

// 根据配置的菜单获取对应的JSX结构
export const getMenuElement = (menus: MenuItem[]) => {
  if (!menus || !menus.length) {
    return null;
  }
  const el = menus.map(menu => {
    if (menu.hide || menu.hideInMenu) {
      return null; // 设置隐藏的菜单不展示在菜单列表中
    }
    if (menu.subMenus) {
      return (
        <SubMenu
          key={menu.to}
          title={
            <span>
              {menu.icon}
              <span>{menu.name}</span>
            </span>
          }
        >
          {/*递归获取子菜单的结构 */}
          {getMenuElement(menu.subMenus)}
        </SubMenu>
      );
    } else {
      return (
        <Menu.Item key={menu.to}>
          {menu.icon}
          <Link to={menu.to}>{menu.name}</Link>
        </Menu.Item>
      );
    }
  });

  return el;
};

// 在菜单配置中找到路径对应菜单的key ---- TODO：方法有点复杂。多做测试
export const getKeys = (
  pathname: string, // 对应history.location.pathname
  menus: MenuItem[],
  initKeys: MenuKeys = {
    open: [],
    selected: [],
  }
): MenuKeys => {
  const menu = menus.find(menu => isMatchRoute(pathname, menu.to));

  // 路径匹配在菜单中匹配不到，返回当前菜单的默认菜单key配置
  if (!menu) {
    return getDefaultKeys(menus, initKeys);
  }

  if (!menu.subMenus) {
    // 没有subMenus的要求完全匹配
    if (pathname === menu.to || pathname === menu.to + '/') {
      return {
        open: initKeys.open,
        selected: [...initKeys.selected, menu.to],
      };
    }
    return getDefaultKeys(menus, initKeys);
  }

  // 递归寻找子菜单
  return getKeys(pathname, menu.subMenus, {
    open: [...initKeys.open, menu.to],
    selected: initKeys.selected,
  });
};

const isMatchRoute = (path: string, route: string): boolean => {
  // 完全相等匹配
  if (path === route) {
    return true;
  }

  const idx = path.indexOf(route);
  // indexOf失败不匹配
  if (idx !== 0) {
    return false;
  }

  // /reportccccc  /report/ccc   /report/   /report// /report  /report
  //   ccccc         /ccc          /            //         ''
  const last = path.replace(route, '');
  if (last === '' || last.startsWith('/')) {
    return true;
  }

  return false;
};

// 根据菜单配置生成路由配置
export const getRouteConfig = (
  menus?: MenuItem[]
): React.ReactElement[] | null => {
  if (!menus || !menus.length) {
    return null;
  }

  let routes = [];

  for (const menu of menus) {
    if (menu.hide) {
      continue;
    }
    routes.push(
      menu.subMenus ? (
        <RouteWithMenus
          path={menu.to}
          component={menu.component}
          // FIXME: 这里没有处理超过两层菜单的情况
          menus={menu.subMenus.filter(item => !item.hide)} // 传递子菜单
          key={menu.to}
        />
      ) : (
        <RouteWithMenus
          path={menu.to}
          exact
          component={menu.component}
          key={menu.to}
        />
      )
    );
  }

  // 将菜单配置中的默认展示菜单配置为后备路由
  const defaultRoute = menus.find(menu => menu.default);
  defaultRoute &&
    routes.push(
      <RouteWithMenus
        path="/"
        component={defaultRoute.component}
        key="default"
      />
    );
  console.log(routes);
  return routes;
};
