import React from 'react';

export default function CircleAvatar({
  char,
  fill,
  bgFill,
  fontSize: _fontSize,
  size = 24,
}: {
  bgFill: string;
  fill: string;
  char: string;
  fontSize?: number;
  size?: number;
}) {
  const fontSize = _fontSize ?? size / 2;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size}>
      <g transform={`translate(${size / 2}, ${size / 2})`}>
        <circle style={{ fill: bgFill }} r={size / 2} />{' '}
        <text
          fontSize={fontSize}
          fill={fill}
          textAnchor="middle"
          dominantBaseline="central"
        >
          {char && char.toUpperCase()}
        </text>
      </g>
    </svg>
  );
}
