import React from 'react';
import { Statistic, Row, Col } from 'antd';
import { ISearchSummary } from '../../types/dashboard';
import config from '../../config';
import './index.module.scss';
import i18next from 'i18next';

const { currency, statisticContentColor } = config;
class Summary extends React.Component<ISearchSummary> {
  render() {
    const {
      payment_payable_amount,
      total_orders,
      released,
      pending,
    } = this.props;
    return (
      <div>
        <Row justify="center" align="middle">
          <Col span={16}>
            <Row>
              <Statistic
                title={`${i18next.t('airpayms_total_sales')}(${currency})`}
                value={payment_payable_amount}
                valueStyle={{
                  color: statisticContentColor,
                  fontSize: '30px',
                }}
              />
            </Row>
            <Row>
              <Col span={12}>
                <Statistic
                  title={`${i18next.t('airpayms_released')} (${currency})`}
                  value={released}
                />
              </Col>
              <Col span={12}>
                <Statistic
                  title={`${i18next.t('airpayms_pending')} (${currency})`}
                  value={pending}
                />
              </Col>
            </Row>
          </Col>
          <Col span={8}>
            <Row>
              <Statistic
                title={i18next.t('airpayms_total_orders')}
                value={total_orders}
                valueStyle={{
                  color: statisticContentColor,
                  fontSize: '30px',
                }}
              />
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Summary;
