import React, { useCallback, useState, useMemo } from 'react';
import { Form, DatePicker } from 'antd';
import moment, { Moment, MomentInputObject } from 'moment';
import 'moment-timezone';
import i18next from 'i18next';

import { useClick } from '../../../hooks/track';
import { getDateRange, TimeType } from '../../../utils/time';
import { DateType } from '../../../enums';
import config from '../../../config';
const { timezone = '' } = config;

const { RangePicker } = DatePicker;

const DefaultLimitTimeRange: MomentInputObject = { years: 1 };

type DateSelectProps = {
  label?: string;
  type?: TimeType;
  onRangeChange: Function;
  from?: string;
  limitTimeRange?: MomentInputObject; // 限制开始时间与结束时间的间隔时间，默认一年： { years: 1 }
};

const DateSelect: React.FC<DateSelectProps> = ({
  label,
  type,
  from,
  onRangeChange,
  limitTimeRange = DefaultLimitTimeRange,
}: DateSelectProps) => {
  const [dates, setDates] = useState([] as Moment[]);
  const reportCustomizedClick = useClick({
    pageType: from || '',
    targetType: 'time_filter_customized',
  });

  const format = useMemo(() => {
    return type !== 'date' ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD';
  }, [type]);

  const disabledDate = (current: Moment) => {
    if (!dates || dates.length === 0) {
      return false;
    }
    const limitMaxTime = getMaxLimitedMoment(dates[0]);

    const tooLate = current.diff(limitMaxTime, 'seconds') > 0;
    return tooLate;
  };

  // 根据开始时间，获取合法范围内的最大结束时间
  const getMaxLimitedMoment = (startTime: Moment): Moment => {
    const startMoment = moment.tz(startTime, timezone);
    return startMoment.add(limitTimeRange).subtract({ second: 1 });
  };

  // 校验开始时间与结束时间是否在 合法的范围内
  const isDatesInValidLimitedRange = (
    dates: [Moment | undefined, Moment | undefined] | null
  ): boolean => {
    if (!dates || !dates[0] || !dates[1]) {
      return true;
    }
    const limitMaxTime = getMaxLimitedMoment(dates[0]);

    return dates[1].diff(limitMaxTime) <= 0;
  };

  const handleCalendarChange = (dates: any) => {
    if (!isDatesInValidLimitedRange(dates)) {
      setDates([dates[0], getMaxLimitedMoment(dates[0])] as Moment[]);
    } else {
      setDates(dates as Moment[]);
    }
  };

  const handleRangeChange = useCallback(
    (dates, dateStrings) => {
      reportCustomizedClick();
      if (!isDatesInValidLimitedRange(dates)) {
        setDates([dates[0], getMaxLimitedMoment(dates[0])] as Moment[]);
        onRangeChange(
          [dates[0], getMaxLimitedMoment(dates[0])],
          [dateStrings[0], getMaxLimitedMoment(dateStrings[0]).format(format)]
        );
      } else {
        setDates(dates);
        onRangeChange(dates, dateStrings);
      }
    },
    [reportCustomizedClick, onRangeChange]
  );

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      <Form.Item
        label={label || i18next.t('airpayms_filter_time')}
        name="time_range"
      >
        <RangePicker
          disabledDate={disabledDate}
          showTime={
            type !== 'date'
              ? {
                  format: 'HH:mm',
                  defaultValue: getDateRange(DateType['This Month']),
                }
              : undefined
          }
          ranges={{
            [i18next.t('airpayms_today')]: [
              moment().startOf('day'),
              moment().endOf('day'),
            ],
            [i18next.t('airpayms_this_week')]: [
              moment().startOf('week'),
              moment().endOf('week'),
            ],
            [i18next.t('airpayms_this_month')]: [
              moment().startOf('month'),
              moment().endOf('month'),
            ],
          }}
          format={format}
          onCalendarChange={handleCalendarChange}
          onChange={handleRangeChange}
        />
      </Form.Item>
    </div>
  );
};

export default DateSelect;
