/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable i18next/no-literal-string */
/**
 * Shopee SG track hooks
 * https://confluence.shopee.io/pages/viewpage.action?spaceKey=SDE&title=Tracking+V3+SDK+Integration+Guide
 * https://confluence.shopee.io/display/SDSB/Guide+for+Adding+a+Tracker
 */

import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import globalTracker from '../utils/tracker';
import Store from '../types/store';

type ActionInfo = {
  pageType: string;
  pageSection?: string;
  targetType?: string;
};

const ToNumber = (value: any) => {
  const result = Number(value);
  return isNaN(result) ? 0 : result;
};

export const getStaffId = (state: Store.State) =>
  ToNumber(state.account.staff_id);
export const getMerchantId = (state: Store.State) =>
  ToNumber(state.account.merchant_id);
export const getTopMerchantId = (state: Store.State) => {
  ToNumber(state.account.top_merchant_id);
};

/**
 * report view action
 */
export function useView({ pageType, targetType, pageSection }: ActionInfo) {
  // report every time didMount
  const top_merchant_id = useSelector(getTopMerchantId);
  const merchant_id = useSelector(getMerchantId);
  const staff_id = useSelector(getStaffId);

  useEffect(() => {
    globalTracker.sync({
      data: {
        type: 'v3',
        info: {
          page_type: pageType,
          operation: 'view',
          page_section: pageSection,
          target_type: targetType,
          data: {
            // view_common is general definition
            view_common: {
              is_initial: true,
              is_back: false,
              // history_id: "xxx",
              // marketing_token: "xxx"
            },
            merchant_id: merchant_id || top_merchant_id || 0,
            staff_id,
            // "key1": "value1",
          },
          usage_id: 0, // user behavior is 0 and performance data is 1
        },
        timestamp: Date.now(),
        source: 'pc', // means which platform, e.g android,ios,rn,rweb,mweb and so on
      },
    });
  }, [pageType, targetType, pageSection]);
}

/**
 * report impression action
 */
export function useImpression({
  pageType,
  pageSection,
  targetType,
}: ActionInfo) {
  const top_merchant_id = useSelector(getTopMerchantId);
  const merchant_id = useSelector(getMerchantId);
  const staff_id = useSelector(getStaffId);

  // report every time didMount
  useEffect(() => {
    globalTracker.sync({
      data: {
        type: 'v3',
        info: {
          page_type: pageType,
          operation: 'impression',
          page_section: pageSection,
          target_type: targetType,
          data: {
            merchant_id: merchant_id || top_merchant_id || 0,
            staff_id,
          },
          // data: { viewed_objects: [ { key1: 'value1', key2: 'value2', }, ], },
          usage_id: 0,
        },
        timestamp: Date.now(),
        source: 'pc',
      },
    });
  }, [pageType, targetType, pageSection]);
}

/**
 * report click action when user click
 */
export function useClick({
  pageType,
  // afterReport,
  pageSection,
  targetType,
}: ActionInfo & { afterReport?: (...args: any[]) => void }) {
  const top_merchant_id = useSelector(getTopMerchantId);
  const merchant_id = useSelector(getMerchantId);
  const staff_id = useSelector(getStaffId);

  return useCallback(
    (...args) => {
      //第一个参数传递要上报的数据
      const curData = args[0] || {};
      globalTracker.sync({
        data: {
          type: 'v3',
          info: {
            page_type: pageType,
            operation: 'click',
            page_section: pageSection,
            target_type: targetType,
            data: {
              merchant_id: merchant_id || top_merchant_id || 0,
              staff_id,
              ...curData,
            },
            // data: { viewed_objects: [ { key1: 'value1', key2: 'value2', }, ], },
            usage_id: 0,
          },
          timestamp: Date.now(),
          source: 'pc',
        },
      });
      // afterReport && afterReport(...args);
    },
    [pageType, targetType, pageSection]
  );
}
