import _ from 'lodash';
import BaseType from '../types';
/**
 * enum switch to obj with key-value
 */
const enum2SelectList = function(source: any): BaseType.SelectItem[] {
  const ret: BaseType.SelectItem[] = [];

  for (const item in source) {
    if (_.isNaN(Number(item))) {
      const obj: any = {};
      obj.value = source[item];
      obj.label = item;
      ret.push(obj);
    }
  }
  return ret;
};

const connectWordsWith_ = (str: string): string => {
  return str
    .split(' ')
    .map((item: string) => item.toLowerCase())
    .join('_');
};

export { enum2SelectList, connectWordsWith_ };
