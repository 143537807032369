import React, {
  useMemo,
  useState,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import { Form, Select, message } from 'antd';
import APASelect from '../../../components/APASelect';
import { useClick } from '../../../hooks/track';
import Store from '../../../types/store';
import { MAX_PAGE_SIZE } from '../../../store/main';
import { postOutlets } from '../../../utils/network';
import _ from 'lodash';
import { SearchType } from '../../../enums';

const { Option } = Select;

type OutletSelectProps = {
  outer_merchant_id?: string | number;

  formItemLabel?: string;
  formItemName?: string;
  from?: string;
};

const targetType = 'outlet_filter';

const OutletSelect: React.FC<OutletSelectProps> = (
  props: OutletSelectProps
) => {
  const storeTotal = useSelector((state: Store.State) => state.outlet.total);
  const storeList = useSelector((state: Store.State) => state.outlet.list);
  const isFetchOutlets = useSelector(
    (state: Store.State) => state.isFetchOutlets
  );
  const storeMerchantList = useSelector(
    (state: Store.State) => state.merchant.list
  );
  const storeMerchantId = useSelector(
    (state: Store.State) => state.account.merchant_id
  );

  const [list, setList] = useState(storeList);
  const [total, setTotal] = useState(storeTotal);
  const searchParams = useRef({ name: '', page_no: 1 });

  useEffect(() => {
    setList(storeList);
    setTotal(storeTotal);
  }, [storeTotal, storeList]);

  // 是否强制展示组件
  const isForceShow = useMemo(() => {
    return !isFetchOutlets && storeMerchantList.length > 0;
  }, [isFetchOutlets, storeMerchantList.length]);

  const searchType = useMemo(() => {
    if (isForceShow || storeTotal > MAX_PAGE_SIZE) {
      return SearchType['remote'];
    } else {
      return SearchType['local'];
    }
  }, [isForceShow, storeTotal]);

  const fetchData = useCallback(async () => {
    const { name, page_no } = searchParams.current;

    try {
      const data = await postOutlets(
        _.pickBy(
          {
            merchant_id: props.outer_merchant_id,
            outlet_name: name,
            page_no,
            page_size: MAX_PAGE_SIZE,
          },
          _.identity
        )
      );

      setList(list =>
        page_no === 1 ? [...data.items] : [...list, ...data.items]
      );
      setTotal(data.count);
    } catch (e) {
      setList([]);
      setTotal(0);
      e.message && message.error(e.message);
    }
  }, [props.outer_merchant_id]);

  useEffect(() => {
    // 账户对应标准商户，门店列表不受outer_merchant_id影响
    if (Number(storeMerchantId)) {
      return;
    }

    if (!props.outer_merchant_id) {
      setList([]);
      setTotal(0);
      return;
    }

    searchParams.current = { name: '', page_no: 1 };
    fetchData();
  }, [storeMerchantId, props.outer_merchant_id, fetchData]);

  const handleSearch = useCallback(
    (value: string) => {
      searchParams.current = { name: value, page_no: 1 };
      fetchData();
    },
    [fetchData]
  );

  const handleScrollToBottom = useCallback(() => {
    const { name, page_no } = searchParams.current;

    const maxPage = Math.ceil(total / MAX_PAGE_SIZE);
    if (page_no >= maxPage) {
      return;
    }

    searchParams.current = { name, page_no: page_no + 1 };
    fetchData();
  }, [total, fetchData]);

  const reportClick = useClick({
    pageType: props.from || '',
    targetType,
  });

  const options =
    list &&
    list.map(outlet => {
      return (
        <Option value={outlet.id} key={outlet.id}>
          {outlet.name}
        </Option>
      );
    });

  const selectEl =
    searchType === SearchType['local'] ? (
      <APASelect
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) =>
          option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        style={{ width: '190px' }}
        placeholder={i18next.t('airpayms_select_outlet')}
        onChange={() => reportClick()}
        pageType={props.from}
        targetType={targetType}
        notFoundContent=""
        allowClear
      >
        {options}
      </APASelect>
    ) : (
      <APASelect
        showSearch
        filterOption={false}
        onChange={() => reportClick()}
        onSearch={_.debounce(handleSearch, 800)}
        onScrollToBottom={handleScrollToBottom}
        style={{ width: '190px' }}
        placeholder={i18next.t('airpayms_select_outlet')}
        pageType={props.from}
        targetType={targetType}
        notFoundContent=""
        allowClear
      >
        {options}
      </APASelect>
    );

  const formItemEl = (
    <Form.Item
      label={props.formItemLabel || i18next.t('airpayms_filter_outlet')}
      name={props.formItemName || 'outlet_id'}
    >
      {selectEl}
    </Form.Item>
  );

  if (isForceShow) {
    return formItemEl;
  }

  if (!storeTotal || storeTotal < 1) {
    return null;
  }
  return formItemEl;
};

export default OutletSelect;
