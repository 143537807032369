import React from 'react';
import { Layout } from 'antd';
import SiderMenu from './menus';
import style from './index.module.scss';
import Header from './Header';
import CopyRight from './CopyRight';
import { IS_MOBILE } from '../utils/constants';
import config from '../config';

const { Sider, Content } = Layout;

const DefaultLayout: React.FC = props => {
  return (
    <Layout>
      <Header></Header>
      {IS_MOBILE ? (
        <Layout style={{ minHeight: '100vh' }}>
          <SiderMenu />
          <Content>{props.children}</Content>
          <CopyRight style={{ margin: '15px' }} />
        </Layout>
      ) : (
        <Layout style={{ minHeight: '100vh' }}>
          <Sider
            theme="light"
            className={`${style.sider} ${
              config.isShopeePay ? style.shopee_pay : ''
            }`}
          >
            <SiderMenu />
            <CopyRight
              style={{
                position: 'fixed',
                bottom: 10,
                width: 200,
                paddingRight: 10,
              }}
            />
          </Sider>
          <Content>{props.children}</Content>
        </Layout>
      )}
    </Layout>
  );
};

export default DefaultLayout;
