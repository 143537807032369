import axios, { AxiosResponse } from 'axios';
import { message } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import BaseType, { Notification } from '../types';
import Store from '../types/store';
import store from '../store';
import { setStaffInfo, INIT_STATE } from '../store/account';

import { getTimestampFromTimeRange } from '../utils/time';

import { ISearchParams, ISearchSummary } from '../types/dashboard';

import Transaction from '../types/transaction';
import { LangKey } from '@merchant-portal/shared/lib/locales';
import { Search as SettlementSearch } from '../pages/report/settlement';

import i18next from 'i18next';
import batchTransfer from '../types/batchTransfer';
import transactionHistory from '../types/transactionHistory';

export const timeout = 5000; // 5s

export const ErrorCodes = {
  /** 鉴权错误 */
  AUTHORIZATION: 101,
  /** 数据检验错误 */
  VALIDATION: 100,
  /** 验证码过期 */
  VERIFY_CODE_EXPIRED: 32312,
  /** 验证码不匹配 */
  VERIFY_CODE_NOT_MATCH: 32311,
  /** 账号名或密码错误 */
  ACCOUNT_ERROR: 32310,
  /** 账号不存在 */
  ACCOUNT_NOT_EXISTS: 32105,
  /** 账号未激活 */
  ACCOUNT_INACTIVE: 32211,
  /** 密码非强密码 **/
  PASSWORD_WEAK: 31104,
  /** 修改密码时旧密码不对， */
  OLD_PASSWORD_NOT_MATCH: 32104,
};

const Error_Key_I18: { [props: number]: LangKey } = {
  [ErrorCodes.VERIFY_CODE_EXPIRED]: 'airpayms_verification_code_expired',
  [ErrorCodes.VERIFY_CODE_NOT_MATCH]: 'airpayms_verification_code',
  [ErrorCodes.ACCOUNT_INACTIVE]: 'airpayms_account_invalid',
  [ErrorCodes.ACCOUNT_ERROR]: 'airpayms_account_password_error',
  [ErrorCodes.ACCOUNT_NOT_EXISTS]: 'airpayms_account_password_error',
  [ErrorCodes.PASSWORD_WEAK]: 'airpayms_changepass_password_failed',
  [ErrorCodes.OLD_PASSWORD_NOT_MATCH]: 'airpayms_account_password_error',
};

// 同时多个请求时，可能会都触发登录过期的错误而导致多个相同弹框
message.config({
  maxCount: 1,
});

const selfApis = axios.create({
  // eslint-disable-next-line i18next/no-literal-string
  baseURL: '/api',
  timeout,
});

class CustomError extends Error {
  constructor(message: string, public result_code?: number, public data?: any) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(message);
  }
}

selfApis.interceptors.response.use(({ data }: AxiosResponse<BaseType.Res>) => {
  if (!data) return;
  const { result_code, result_message } = data;

  // 未登陆，重置store中的staff信息
  if (result_code === ErrorCodes.AUTHORIZATION) {
    message.error(result_message, 5, () => {
      store.dispatch(setStaffInfo(INIT_STATE));
    });
    // 前面已经使用message.error提示用户错误信息
    // 所以抛出message为空的error，防止外部二次提示错误信息
    throw new Error();
  }

  let err: CustomError;

  if (result_code !== 0) {
    err = new CustomError(result_message, result_code, {
      ...data.data,
      msg: i18next.t(Error_Key_I18[result_code]),
    });
  }

  if (
    [ErrorCodes.VERIFY_CODE_EXPIRED, ErrorCodes.VERIFY_CODE_NOT_MATCH].includes(
      result_code
    )
  ) {
    Object.assign(err!.data, { need_verify_code: true });
  }

  // @ts-ignore
  if (err) {
    throw err;
  }

  return data.data;
});

//===================account==============================
export function login(data: {
  username: string;
  password: string;
  otp: string;
}): Promise<IAccount> {
  return selfApis.post('/login', data);
}

export function logout() {
  return selfApis.post('/logout');
}

export function changePwd(data: {
  old_password: string;
  new_password: string;
}) {
  return selfApis.post('/change_pwd', data);
}

export function changeNotLoginPwd(data: {
  old_password: string;
  new_password: string;
  username?: string;
  verify_code?: {
    verify_code_id: string;
    verify_code_value: string;
  };
}) {
  return selfApis.post('/change_notlogin_pwd', data);
}

export const getRefreshCode = ({
  height,
  width,
}: {
  height: number;
  width: number;
}): Promise<any> => {
  return selfApis.post('/refresh_otp', {
    height,
    width,
  });
};

export const changeLang = (lang: string) => {
  // 接口更新数据库
  return selfApis.post('/change_lang', {
    lang,
  });
};

export const postProducts = (): Promise<any> => {
  return selfApis.post('/products/');
};

//===================merchants/outlets==============================
export const postMerchants = (params: any): Promise<any> => {
  return selfApis.post('/merchants', params);
};

export const postOutlets = (params: any): Promise<any> => {
  return selfApis.post('/merchants/outlets', params);
};

//===================dashboard==============================

/**
 * 通用的转换接口参数的方法
 * @param params
 * do what:
 * 1.将time_range转化为start_time，end_time
 * 2.删除接口不需要的参数
 * 3.删除参数中的undefined值
 */
const commonTransParams = (params: {
  time_range?: moment.Moment[];
  [props: string]: any;
}) => {
  const [start_time, end_time] = getTimestampFromTimeRange(params.time_range);

  const finalParams = _.pickBy(
    {
      ...params,
      start_time,
      end_time,
    },
    _.identity
  );

  delete finalParams.time_range;

  return finalParams;
};

/**
 * 30天交易数据
 */
export const postSummary = (): Promise<any> => {
  return selfApis.post('/transactions/summary', undefined, { timeout: 10000 });
};

/**
 * 获取指定时间内的交易统计数据
 * 用于:
 * 1.dashboard页交易数据统计 "include_voucher": true
 * 2.report/transaction/purchase  页交易数据统计  "include_voucher": false
 * @param params
 */
export const postSearchStat = (
  params: ISearchParams
): Promise<ISearchSummary> => {
  return selfApis.post('/transactions/search_stat', commonTransParams(params), {
    timeout: 10000,
  });
};

//===================report==============================

/**
 * 查询 transaction purchase 订单
 * @param params
 */
export const postPurchase = (
  params: Transaction.IPageParams & Transaction.ISearchParams
): Promise<any> => {
  return selfApis.post('/transactions/purchase', commonTransParams(params));
};

/**
 * 订单退款
 * @param params
 */
export const refund = (params: any): Promise<any> => {
  return selfApis.post('/refund', params);
};

/**
 * 查询退款订单
 * @param params
 */
export const postRefunded = (
  params: Transaction.ISearchParams & Transaction.IPageParams
): Promise<any> => {
  return selfApis.post('/transactions/refunded', commonTransParams(params));
};

/**
 * 获取 voucher 订单
 * @param params
 */
export const postVoucher = (
  params: Transaction.ISearchParams & Transaction.IPageParams
): Promise<any> => {
  return selfApis.post('/transactions/voucher', commonTransParams(params), {
    timeout: 30000,
  });
};

export const postSettlement = (
  params: SettlementSearch & Transaction.IPageParams
): Promise<any> => {
  return selfApis.post('/settlement', commonTransParams(params));
};

//===================notification==============================

/**
 * 获取商户未读消息数量
 */
export const getUnreadNotificationCount = (): Promise<{
  total: number;
  promotionCount: number;
  systemCount: number;
}> => {
  return selfApis.get('/notification/unreadCount');
};

/**
 * 获取商户消息列表
 */
export const postNoficationList = (params: {
  start_id: number; // 第一次请求传 0; 之后请求传当前消息列表最后一条的消息 ID
  category: number; // 消息类型。Promotion: 6, System: 7
  max_count: number; // 请求消息数量（最大为 50）
}): Promise<Notification[]> => {
  return selfApis.post('/notification/list', params);
};

/**
 * 商户消息状态更新
 */
export const updateNotificationStatus = (params: {
  category?: number; // 类型，如果 message_id_list 有值则不考虑此字段
  message_id_list?: number[]; // 消息 ID 列表（最大为 50）
  message_status: number; // 消息状态：0：未读，1：已读，2：删除
}): Promise<any> => {
  return selfApis.post('/notification/updateStatus', params);
};

export const postTaxInvoice = (params: any): Promise<any> => {
  return selfApis.post('/taxInvoice', commonTransParams(params));
};

export default selfApis;

//=================== Wallet ==============================
/*
  钱包余额 ——  /wallet/balance

  钱包交易历史 —— /wallet/transactionHistory
  download钱包交易历史 —— /wallet/transactionHistory/download

  转账历史 —— /wallet/transferHistory
  download 转账历史 —— /wallet/transferHistory/download/:type
  
  批量转账 ——  /wallet/batchTransfer
  批量转账操作历史 —— /wallet/batchTransfer/history
  feedback download ——  /wallet/batchTransfer/download
 */

/**
 * transaction history - wallet balance
 * 钱包余额
 */
export const getWalletBalance = (): Promise<any> => {
  return selfApis.post('/wallet/balance');
};

/**
 * batch transfer - transfer history
 * 获取转账历史
 */
export const postTransferHistory = (
  params: batchTransfer.ISearchParams
): Promise<any> => {
  return selfApis.post('/wallet/transferHistory', commonTransParams(params));
};

// transfer history download 【请求在页面代码中】

/**
 * batch transfer - transfer - operation log
 * 获取转账操作历史
 */
export const postTransferOperationHistory = (
  params: batchTransfer.IPageParams
): Promise<any> => {
  return selfApis.post(
    '/wallet/batchTransfer/history',
    commonTransParams(params)
  );
};

export const postTransactionHistory = (
  params: transactionHistory.ISearchParams
): Promise<any> => {
  return selfApis.post('/wallet/transactionHistory', commonTransParams(params));
};

export const postWalletBalance = (): Promise<any> => {
  return selfApis.post('/wallet/balance');
};

export const uploadBatchTransferFile = (file: any): Promise<any> => {
  let param = new FormData();
  param.append('file', file);
  return selfApis.post('/wallet/upload', param, {
    headers: {
      'content-type':
        'multipart/form-data; boundary=----WebKitFormBoundary7bnSqbTcwPwJYFy6',
    },
  });
};

export type batchTransferParams = {
  filename?: string;
  memo?: string;
  force?: boolean; // 当二次确认强制上传时；
  current_step?: string; //上次CreateBatchPayOutRsp.current_steps的透传:
};

export const walletBatchTransfer = (
  params: batchTransferParams
): Promise<any> => {
  return selfApis.post('/wallet/batchTransfer', commonTransParams(params));
};

/**
 * 灰度接口
 */
export const getWebPortalGreyScale = (
  params: Pick<IAccount, 'top_merchant_id' | 'merchant_id'>
): Promise<any> => {
  return selfApis.post('/greyscale/', params);
};
