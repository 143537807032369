import * as Sentry from '@sentry/browser';
import isDev from './utils/isDev';

import { version } from '../package.json';
import { appEnv } from './utils/constants';

export const SENTRY_DSN =
  'https://c5aaf015d8d244b4b49ff04513251946@sentry.lenteradana.co.id/48';

export default () => {
  Sentry.init({
    // sentry project
    dsn: SENTRY_DSN,

    // dont send event when development
    enabled: !isDev,
    debug: isDev,

    // distinguish different envs
    environment: appEnv,
    // add release tag
    release: version,

    // sampling
    sampleRate: 0.3,
  });
};
