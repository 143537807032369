import React, { useState, useMemo } from 'react';
import { Upload, Button, Input, Modal, message } from 'antd';
import { useSelector } from 'react-redux';
import i18next from 'i18next';
import {
  DownloadOutlined,
  InboxOutlined,
  FileExcelOutlined,
  FileDoneOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { LangKey } from '@merchant-portal/shared/lib/locales';

import style from '../index.module.scss';
import Loading from '../../../../components/Loading';
import { appRegion } from '../../../../utils/constants';
import {
  uploadBatchTransferFile,
  walletBatchTransfer,
  batchTransferParams,
} from '../../../../utils/network';
import Store from '../../../../types/store';

const { Dragger } = Upload;

const MAX_ROW_LIMIT = 1000; // excel 上传的最大行数

type Props = {
  onSubmitCallback?: () => void;
};

enum UploadStatus {
  Init = 1,
  Uploading,
  Done,
  FileTypeError,
  FileSizeError,
  TotalCountError,
}

const getStatusIcon = (status: UploadStatus): JSX.Element => {
  switch (status) {
    case UploadStatus.Init:
      return <InboxOutlined />;
    case UploadStatus.Uploading:
      return <LoadingOutlined />;
    case UploadStatus.Done:
      return <FileDoneOutlined />;
    default:
      return <FileExcelOutlined />;
      break;
  }
};

const getStatusText: {
  [key in UploadStatus]: [LangKey, string];
} = {
  [UploadStatus.Init]: ['airpayms_transfer_file', 'rgba(0, 0, 0, 0.43)'],
  [UploadStatus.Uploading]: [
    'airpayms_transfer_upload_processing',
    'rgba(0, 0, 0, 0.43)',
  ],
  [UploadStatus.Done]: [
    'airpayms_transfer_upload_ready',
    'rgba(0, 0, 0, 0.43)',
  ],
  [UploadStatus.FileTypeError]: [
    'airpayms_transfer_upload_failed_type',
    'rgba(238, 44, 74, 1)',
  ],
  [UploadStatus.FileSizeError]: [
    'airpayms_transfer_upload_failed_size',
    'rgba(238, 44, 74, 1)',
  ],
  [UploadStatus.TotalCountError]: [
    'airpayms_transfer_upload_failed_count',
    'rgba(238, 44, 74, 1)',
  ],
};

const UploadFileCom = (props: Props) => {
  const [memo, setMemo] = useState('');
  const [fileList, setFileList] = useState([] as any[]); // 只能在 beforeUpload 阶段保存 File 类型的数据，但是组件中对 fileList 的要求是 UploadFile 类型
  const [status, setStatus] = useState(UploadStatus.Init as UploadStatus);
  const [loading, setLoading] = useState(false);

  const lang = useSelector((state: Store.State) => state.account.language);

  const downloadTemplate = () => {
    window.location.href = '/api/wallet/BatchTransferTemplate';
  };

  const statusText = useMemo(() => {
    const [langkey, color] = getStatusText[status];
    return [i18next.t(langkey), color];
  }, [status, lang]);

  const statusIcon = useMemo(() => {
    return getStatusIcon(status);
  }, [status]);

  const beforeUpload = (file: File) => {
    setStatus(UploadStatus.Uploading);

    // 类型判断
    const isCSV =
      file.type.includes('csv') || file.type === 'application/vnd.ms-excel';
    if (!isCSV) {
      setStatus(UploadStatus.FileTypeError);
      return false;
    }

    // 文件大小判断
    const isLt1M = file.size / 1024 / 1024 < 1;
    if (!isLt1M) {
      setStatus(UploadStatus.FileSizeError);
      return false;
    }

    // 文件行数判断
    const checkRowsLimit = () => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = () => {
          const fileData = reader.result as string;
          // 使用换行切割出每一行
          const rows = fileData.split('\n').filter(row => {
            return !!row;
          });
          resolve(rows && rows.length - 1 <= MAX_ROW_LIMIT); // 去除表头一行
        };
      });
    };

    checkRowsLimit().then(isRowLimitValid => {
      if (!isRowLimitValid) {
        setStatus(UploadStatus.TotalCountError);
      } else {
        setStatus(UploadStatus.Done);
        setFileList([file]);
      }
    });

    // 自己控制文件上传
    return false;
  };
  const handleUpload = (info: any) => {
    const { status } = info.file;
    if (status === 'removed') {
      setStatus(UploadStatus.Init);
      setFileList([]);
    }
  };

  const handleAction = async () => {
    if (!fileList || !fileList.length) {
      return;
    }
    setLoading(true);
    let paramsBase: batchTransferParams = {};
    const doRequest = async (params: batchTransferParams) => {
      walletBatchTransfer(params)
        .then(res => {
          const {
            current_step,
            popup_title,
            popup_content,
            popup_title_local,
            popup_content_local,
            code,
          } = res;
          const title = lang === 'en' ? popup_title : popup_title_local;
          const content = lang === 'en' ? popup_content : popup_content_local;
          // 文件上传没问题
          if (code === 0) {
            message.success(
              i18next.t('airpayms_transfer_submit_successful'),
              5
            );
            setStatus(UploadStatus.Init);
            setFileList([]);
            setMemo('');
            props.onSubmitCallback && props.onSubmitCallback();
            return;
          }
          // 文件上传有问题，且没有 title 表示是不可继续进行的错误
          if (code && !title) {
            Modal.error({
              title:
                content ||
                i18next.t('airpayms_transfer_upload_failed_server_error'),
              maskClosable: true,
              okButtonProps: {
                type: 'default',
              },
              okText: i18next.t('airpayms_button_ok'),
            });
            return;
          }
          // 弹框错误，点击继续，可以继续强制执行
          if (title) {
            Modal.warning({
              title: title || '',
              content: content || '',
              okText: i18next.t('airpayms_upload_button'),
              cancelText: i18next.t('airpayms_button_cancel'),
              maskClosable: true,
              okCancel: true,
              onOk: () => {
                doRequest({
                  ...paramsBase,
                  force: true,
                  current_step: current_step || undefined,
                });
              },
              onCancel: () => {
                console.log('cancel');
              },
            });
          }
        })
        .catch(err => {
          console.log('err:', err.result_code, err.message);
          const { result_code, message } = err;
          Modal.error({
            title:
              (result_code === -2 && message) ||
              i18next.t('airpayms_transfer_upload_failed_server_error'),
            maskClosable: true,
            okButtonProps: {
              type: 'default',
            },
            okText: i18next.t('airpayms_button_ok'),
          });
        });
    };
    await uploadBatchTransferFile(fileList[0])
      .then(async uploadRes => {
        if (!uploadRes || !uploadRes.filename) {
          Modal.error({
            title: i18next.t('airpayms_transfer_upload_failed_server_error'),
            maskClosable: true,
            okButtonProps: {
              type: 'default',
            },
            okText: i18next.t('airpayms_button_ok'),
          });
          setLoading(false);
          return;
        }

        paramsBase = {
          memo,
          filename: uploadRes?.filename,
        };
        await doRequest(paramsBase);
      })
      .catch(err => {
        Modal.error({
          title: i18next.t('airpayms_transfer_upload_failed_server_error'),
          maskClosable: true,
          okButtonProps: {
            type: 'default',
          },
          okText: i18next.t('airpayms_button_ok'),
        });
        return;
      });
    setLoading(false);
  };

  return (
    <div className={style.uploadFileContainer}>
      <Loading
        show={loading}
        styles={{
          height: 'auto',
          zIndex: 1,
        }}
      />
      <div className={style.uploadContent}>
        <div className={style.downloadTemplate}>
          <span>
            {i18next.t('airpayms_button_download')}{' '}
            {i18next.t('airpayms_transfer_template')}:
          </span>
          <Button className={style.downloadBtn} onClick={downloadTemplate}>
            <DownloadOutlined />
            {i18next.t('airpayms_button_download')}
          </Button>
        </div>
        <div className={style.uploadFile}>
          <p className={style.uploadFileTitle}>
            {i18next.t('airpayms_transfer_upload')}:
          </p>
          <Dragger
            accept=".csv, text/csv"
            name="file"
            multiple={false}
            fileList={fileList}
            beforeUpload={beforeUpload}
            onChange={handleUpload}
          >
            <p className="ant-upload-drag-icon">{statusIcon}</p>
            <p className="ant-upload-text">
              {i18next.t('airpayms_transfer_browse')}
            </p>
            <p
              className="ant-upload-hint"
              style={{
                wordWrap: 'break-word',
                color: statusText[1],
              }}
            >
              {statusText[0]}
            </p>
          </Dragger>
        </div>
        <div
          className={style.remark}
          style={{
            marginTop: status === UploadStatus.Done ? '20px' : '0px',
          }}
        >
          <p className={style.remarkTitle}>
            {i18next.t('airpayms_transfer_log_remark')}:
          </p>
          <Input
            value={memo}
            placeholder={i18next.t('airpayms_transfer_log_remark_hint')}
            allowClear
            maxLength={16}
            onChange={(e: any) => {
              setMemo(e.target?.value || '');
            }}
          />
        </div>
        <div className={style.submitContainer}>
          <Button
            className={`${style.submitBtn} ${
              status !== UploadStatus.Done || loading ? style.disableBtn : ''
            }`}
            disabled={status !== UploadStatus.Done || loading}
            onClick={handleAction}
          >
            {i18next.t('airpayms_submit_button')}
          </Button>
        </div>
      </div>
      <div className={style.uploadTextNotice}>
        <p>{i18next.t('airpayms_transfer_notice')}</p>
        <p>
          {i18next.t(
            `airpayms_transfer_specification_${
              appRegion === 'vn' ? 'vn' : 'th'
            }`
          )}
        </p>
        <p>{i18next.t(`airpayms_transfer_example_${appRegion}`)}</p>
      </div>
    </div>
  );
};

export default UploadFileCom;
