"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
//
// ─── Transaction History ─────────────────────────────────────────────────────────────
//
exports.schema = {
    type: 'object',
    properties: {
        start_time: { type: 'integer' },
        end_time: { type: 'integer' },
        txn_id: { type: 'integer' },
        transaction_type: { type: 'integer' },
        txn_min_amount: { type: 'string' },
        txn_max_amount: { type: 'string' },
        status: { type: 'integer' },
        memo: { type: 'string' },
    },
};
exports.displayColumns = [
    ['create_time', 'airpayms_create_time_table', 250],
    ['transaction_id', 'airpayms_transfer_history_txn_id', 200],
    ['txn_type_int', 'airpayms_txn_history_txn_type', 200],
    ['transaction_amount', 'airpayms_amount', 150],
    ['status', 'airpayms_voucher_status', 200],
    ['status_int', 'airpayms_voucher_status', 200],
    ['memo', 'airpayms_transfer_log_remark'],
];
