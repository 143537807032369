import React, { useMemo } from 'react';
import { Switch } from 'react-router-dom';
import { MenuItem, getRouteConfig } from '../../layouts/menus/config';

const Wallet = (props: { menus?: MenuItem[] }) => {
  const routeConfig = useMemo(() => getRouteConfig(props.menus), [props.menus]);
  return <Switch>{routeConfig}</Switch>;
};

export default Wallet;
