import _ from 'lodash';
import { NativeEventSource, EventSourcePolyfill } from 'event-source-polyfill';
const EventSource = NativeEventSource || EventSourcePolyfill;

let events: EventSource;
const connectSSE = (onmessage: Function) => {
  events =
    events ||
    new EventSource('/api/notification/sse', {
      withCredentials: true,
    });
  const listener = (event: MessageEvent) => {
    try {
      const data = JSON.parse(event.data).data;
      if (data !== 'newMsg') {
        return;
      }
      onmessage(data);
    } catch (e) {
      console.error('sse message parse error', event);
    }
  };
  const debounceListener = _.debounce(listener, 500);
  events.addEventListener('message', debounceListener);
  return () => {
    events.removeEventListener('message', debounceListener);
  };
};
export default connectSSE;
