import 'react-app-polyfill/ie9';
import 'core-js/features/object/entries';
import 'core-js/features/array/includes';
import 'core-js/features/array/find';
import 'core-js/features/array/find-index';
import 'core-js/features/array/iterator';
import 'core-js/features/string/starts-with';
import 'core-js/features/global-this';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import PrivateRoute from './components/PrivateRoute';
import initI18next from './initI18next';
import sentryInit from './sentry';

import store from './store';

import App from './App';
import Login from './pages/login';
import ChangePass from './pages/changePassNotLogin';

import * as serviceWorker from './serviceWorker';

import './index.scss';
import i18next from 'i18next';
import { REGION_LANGUAGE } from './config';

sentryInit();

const Root = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <PrivateRoute path="/login" exact component={Login} />
          <PrivateRoute path="/changePassword" exact component={ChangePass} />
          <PrivateRoute component={App} />
        </Switch>
      </BrowserRouter>
    </Provider>
  );
};

let prevLang = store.getState().account.language;

// 监听语言改变，更新i18语言
store.subscribe(() => {
  const currentLang = store.getState().account.language;
  if (currentLang !== prevLang) {
    // eslint-disable-next-line i18next/no-literal-string
    i18next.changeLanguage(currentLang || REGION_LANGUAGE || 'en');
    prevLang = currentLang;
  }
});

initI18next(prevLang, err => {
  if (err) {
    alert(err);
    return;
  }
  ReactDOM.render(<Root />, document.getElementById('root'));
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
