import React, { useCallback } from 'react';
import { useImpression } from '../hooks/track';
import { Select } from 'antd';
import { SelectProps, SelectValue } from 'antd/lib/select';

const APASelect = ({
  onScrollToBottom,
  children,
  pageType,
  targetType,
  ...rest
}: SelectProps<SelectValue> & {
  onScrollToBottom?: () => void;
  pageType?: string;
  targetType?: string;
}) => {
  useImpression({ pageType: pageType || '', targetType: targetType || '' });
  const handlePopupScroll = useCallback(
    (e: React.UIEvent<HTMLDivElement>) => {
      const el = e.target as HTMLElement;
      if (el.offsetHeight + el.scrollTop === el.scrollHeight) {
        onScrollToBottom && onScrollToBottom();
      }
    },
    [onScrollToBottom]
  );
  return (
    <Select onPopupScroll={handlePopupScroll} {...rest}>
      {children}
    </Select>
  );
};

export default APASelect;
