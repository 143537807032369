/* eslint-disable i18next/no-literal-string */
import React, { useCallback } from 'react';
import { useClick } from '../../hooks/track';
import {
  useHistory,
  useLocation,
  withRouter,
  RouteComponentProps,
} from 'react-router-dom';
import i18next from 'i18next';
import { Layout, Select, Popover, message } from 'antd';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';
import style from './index.module.scss';
import CircleAvatar from '../../components/CircleAvatar';
import { useSelector, useDispatch } from 'react-redux';
import Store from '../../types/store';
import { logoutThunk } from '../../store/account';
import { setLang } from '../../store/account';
import { Lang_Type } from '../../enums/index';
import { REGION_LANGUAGE } from '../../config';
import { enum2SelectList } from '../../utils/index';
import { changeLang } from '../../utils/network';
import Notice from './components/notice';
import config from '../../config';
import { jumpChangePassPage } from '../../utils/helper';

const langList = enum2SelectList(Lang_Type);
const color = config.mainColor!;

function I18nSelect() {
  const lang = useSelector((state: Store.State) => state.account.language);
  const dispatch = useDispatch();

  const reportClick = useClick({
    pageType: 'ap_ms_web_portal_head',
    targetType: 'language_button',
  });

  const handleChange = useCallback(
    async (value: string) => {
      // 埋点
      reportClick();

      // 切换i18语言
      // i18next.changeLanguage(value);

      // 更新store
      dispatch(setLang(value));

      // 接口更新数据库
      try {
        await changeLang(value);
      } catch (e) {
        e.message && message.error(e.message);
      }
    },
    [reportClick, dispatch]
  );

  return (
    <Select
      dropdownClassName={style.langDropDown}
      value={lang || REGION_LANGUAGE || 'en'}
      bordered={false}
      className={`${style.langChoose} ${
        config.isShopeePay ? style.shopee_pay : ''
      }`}
      onChange={handleChange}
    >
      {langList &&
        langList.map(item =>
          // 只能切换英文和对应站点的语言。th无法切vi，vi无法切th
          [Lang_Type.English, REGION_LANGUAGE].includes(item.value) ? (
            <Select.Option value={item.value} key={item.value}>
              {item.label}
            </Select.Option>
          ) : null
        )}
    </Select>
  );
}

function UserProfile({ history }: RouteComponentProps) {
  const avatarReport = useClick({
    pageType: 'ap_ms_web_portal_head',
    targetType: 'logo_button',
  });

  const changePassReport = useClick({
    pageType: 'ap_ms_web_portal_head',
    targetType: 'change_password_button',
  });

  const logoutReport = useClick({
    pageType: 'ap_ms_web_portal_head',
    targetType: 'logout_button',
  });

  const { username = '', merchant_name, avatar, language } = useSelector(
    (state: Store.State) => {
      return state.account;
    }
  );
  const dispatch = useDispatch();
  const onLogout = useCallback(async () => {
    logoutReport();
    await dispatch(logoutThunk());
    // hard refresh
    globalThis.location.href = '/'; // TODO check
  }, [dispatch, logoutReport]);

  const goToChangePass = useCallback(() => {
    changePassReport();
    // hard refresh
    jumpChangePassPage(language);
  }, [changePassReport, history, language]);

  const content = (
    <div className={style.user}>
      <div className="f-center">
        {avatar ? (
          <img src={avatar} className={style.user__largeAvatar} alt="avatar" />
        ) : (
          <CircleAvatar
            fill="white"
            bgFill={color}
            char={username[0]}
            size={Number(style.avatarLargeSize)}
          />
        )}
        <div className="f-column" style={{ marginLeft: 10, fontSize: 12 }}>
          <div>{username}</div>
          <div>{merchant_name}</div>
        </div>
      </div>
      <div className={style.user__separator} />
      <div className="f-column">
        <div className={style.user__action}>
          <UserOutlined className={style.user__icon} />
          <span onClick={goToChangePass} className="cursor-pointer">
            {i18next.t('airpayms_change_password')}
          </span>
        </div>
        <div
          className={`${style.user__action} cursor-pointer`}
          onClick={onLogout}
        >
          <span>
            <LogoutOutlined className={style.user__icon} />
            {i18next.t('airpayms_log_out')}
          </span>
        </div>
      </div>
    </div>
  );
  return (
    <Popover placement="bottomRight" content={content} trigger={['hover']}>
      <div
        className={`${style.user__smallAvatar} cursor-pointer`}
        onClick={avatarReport}
      >
        {avatar ? (
          <img style={{ width: '100%' }} src={avatar} alt="avatar" />
        ) : (
          <CircleAvatar fill={color} bgFill="white" char={username[0]} />
        )}
      </div>
    </Popover>
  );
}

const UserProfileWithRouter = withRouter(UserProfile);

function LogoComponent() {
  const history = useHistory();

  const location = useLocation();
  const lang = useSelector((state: Store.State) => state.account.language);

  const onHome = useCallback(() => {
    if (location.pathname !== '/') {
      history.push('/');
    }
  }, [location, history]);
  return (
    <div className={`${style.logo} cursor-pointer`} onClick={onHome}>
      {config.wLogoComponent}
      <h1 className={style.logo__text}>
        {i18next.t(
          !config.isShopeePay && lang === 'en'
            ? 'airpayms_airpay_merchant_portal'
            : 'shopeepayms_airpay_merchant_portal'
        )}
      </h1>
    </div>
  );
}

function Header() {
  const top_merchant_id = useSelector((state: Store.State) => {
    const result = Number(state.account.top_merchant_id);
    return isNaN(result) ? 0 : result;
  });
  return (
    <div className={style.header}>
      <Layout.Header
        className={style.header__body}
        style={{ background: color }}
      >
        <LogoComponent />
        <div className="f-center">
          <I18nSelect />
          {/* 只有vn的非顶层商户才显示通知图标 */}
          {config.showNotification && !top_merchant_id ? <Notice /> : null}
          <UserProfileWithRouter />
        </div>
      </Layout.Header>
    </div>
  );
}

export default Header;
