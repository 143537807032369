import React from 'react';
import { useSelector } from 'react-redux';
import { PERMISSIONS } from '../enums';
import Store from '../types/store';

export function useAuthorize(list: PERMISSIONS[]) {
  const permissions = useSelector<
    Store.State,
    Store.State['account']['permissions']
  >(state => state.account.permissions);
  return list.some(item => permissions?.includes(item));
}

/**
 * 权限校验，只有授权用户才显示
 */
export default function AuthorizationGuard({
  children,
  code,
}: {
  tab?: string;
  code: PERMISSIONS[] | PERMISSIONS;
  children: React.ReactElement;
}) {
  const authorized = useAuthorize(Array.isArray(code) ? code : [code]);
  if (authorized) {
    return children;
  }
  return null;
}
