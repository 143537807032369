import React from 'react';
import i18next from 'i18next';
import { Statistic } from 'antd';
import { OneDayForMonthItem } from '../../types/dashboard';
import config from '../../config';
import style from './index.module.scss';

const { currency, statisticContentColor } = config;

type TwoDaysPropsType = {
  today?: OneDayForMonthItem;
  yesterday?: OneDayForMonthItem;
};

const lang_pre = 'airpayms';

function TwoDays(props: TwoDaysPropsType) {
  return (
    <div>
      {Object.entries(props).map(([key, day]) => {
        return (
          <div className={style.aDay} key={key}>
            <span className={style.title}>
              {i18next.t(`${lang_pre}_${key}`)}
            </span>
            <Statistic
              title={`${i18next.t('airpayms_total_sales')} (${currency})`}
              value={day?.total_amount || 0}
              valueStyle={{ color: statisticContentColor, fontSize: '20px' }}
            />
            <Statistic
              title={i18next.t('airpayms_total_orders')}
              value={day?.total_orders || 0}
              valueStyle={{ color: statisticContentColor, fontSize: '20px' }}
            />
          </div>
        );
      })}
    </div>
  );
}

export default TwoDays;
