import React, { useState, useEffect, useCallback } from 'react';
import i18next from 'i18next';
import { useView } from '../../hooks/track';
import { Layout, Card, Spin, message } from 'antd';
import SearchLine from '../../components/business/searchLine';
import Loading from '../../components/Loading';
import style from './index.module.scss';
import Summary from './Summary';
import TwoDays from './TwoDays';
import ChartTab from './ChartTab';
import {
  ISearchParams,
  ISearchSummary,
  OneDayForMonthItem,
} from '../../types/dashboard';

import { getDateRange, FormatTypes } from '../../utils/time';
import { postSummary, postSearchStat } from '../../utils/network';
import { DateType } from '../../enums';

const todayMoments = getDateRange(DateType['Today']);
const thisMonthMoments = getDateRange(DateType['This Month']);
const yesterdayMoments = getDateRange(DateType['Yesterday']);
const todayDateString =
  todayMoments && todayMoments[0] && todayMoments[0].format(FormatTypes.date);
const yesterdayDateString =
  yesterdayMoments &&
  yesterdayMoments[0] &&
  yesterdayMoments[0].format(FormatTypes.date);

// 初始搜索条件
const initSearchParams: ISearchParams = {
  time_range: todayMoments,
};

const pageType = 'ap_ms_web_portal_dashboard';

const DashBoard = () => {
  const [searchParams, setSearchParams] = useState(initSearchParams); //搜索条件
  const [searchData, setSearchData] = useState<ISearchSummary>({}); //搜索结果

  const [summaryLoading, setSummaryLoading] = useState(false);

  const [month, setMonth] = useState<OneDayForMonthItem[]>(); // 当月数据总汇

  // 当天的交易额
  const [today, setToday] = useState<OneDayForMonthItem | undefined>({
    date: '',
    total_amount: '0',
    total_orders: 0,
  });
  // 昨天的交易额
  const [yesterday, setYesterday] = useState<OneDayForMonthItem | undefined>({
    date: '',
    total_amount: '0',
    total_orders: 0,
  });

  useView({ pageType });

  const setTodayAndYesterday = (monthData: OneDayForMonthItem[]) => {
    const today = monthData?.find(day => day.date === todayDateString);
    const yesterday = monthData?.find(day => day.date === yesterdayDateString);

    today && setToday(today);
    yesterday && setYesterday(yesterday);
  };

  useEffect(() => {
    (async function() {
      try {
        const data = await postSummary();
        setMonth(data);
        setTodayAndYesterday(data);
      } catch (e) {
        e.message && message.error(e.message);
      }
    })();
  }, []);

  useEffect(() => {
    (async function() {
      try {
        setSummaryLoading(true);
        const data = await postSearchStat({
          ...searchParams,
          include_voucher: true,
        });
        setSearchData(data);
      } catch (e) {
        e.message && message.error(e.message);
      } finally {
        setSummaryLoading(false);
      }
    })();
  }, [searchParams]);

  const handleSearch = useCallback(async (
    values: any /**TODO: change to real type */
  ) => {
    setSearchParams(values);
  }, []);

  return (
    <div className={`page-item ${style.dashboard}`}>
      <h2>{i18next.t('airpayms_dashboard')}</h2>
      <Layout
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
        }}
      >
        <Layout style={{ width: '80%', flexGrow: 0 }}>
          <Card className={style.cardItem}>
            <SearchLine
              onSearch={handleSearch}
              initTimeRange={thisMonthMoments}
              from={pageType}
            />
          </Card>
          <Card className={`${style.cardItem} ${style.summary}`}>
            <Summary {...searchData} />
            <Loading show={summaryLoading} />
          </Card>
          <Card className={style.cardItem}>
            {month ? <ChartTab data={month} /> : <Spin />}
          </Card>
        </Layout>
        <Layout style={{ width: '18%', flexGrow: 0 }}>
          <Card>
            <TwoDays today={today} yesterday={yesterday} />
          </Card>
        </Layout>
      </Layout>
    </div>
  );
};

export default DashBoard;
