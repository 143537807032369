import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Switch, Redirect } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { Locale } from 'antd/es/locale-provider';

import Layout from './layouts';

import { getRouteConfig, getMenus, MenuItem } from './layouts/menus/config';

import { fetchStaffRelatedInfo, getGreyscale } from './store/main';
import { jumpLoginPage } from './utils/helper';

import Store from './types/store';

import enUS from 'antd/es/locale/en_US';
import viVN from 'antd/es/locale/vi_VN';
import thTH from 'antd/es/locale/th_TH';

import { REGION_LANGUAGE } from './config';
import { appRegion } from './utils/constants';

// antd国际化
const antdLocale: { [props: string]: Locale } = {
  en: enUS,
  th: thTH,
  vi: viVN,
};

type TopMerchantObj = {
  value?: number | string;
  list: undefined;
  show?: boolean;
};

type MerchantObj = {
  value?: number | string;
  list?: Store.IMerchantItem[];
  show?: boolean;
};

type OutletObj = {
  value?: number | string;
  list?: Store.IOutletItem[];
  show?: boolean;
};

export type RelatedInfoContextType = {
  topMerchant: TopMerchantObj;
  merchant: MerchantObj;
  outlet: OutletObj;
};

const App = () => {
  // store中的lang改变。 APP组件重新渲染
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const lang = useSelector((state: Store.State) => state.account.language);
  const account = useSelector((state: Store.State) => state.account);
  const products = useSelector((state: Store.State) => state.products);

  // menus需要在组件中获取，因为只有在组件中才能保证i18已经初始化完成,并且只计算一次
  const menus = useMemo(() => getMenus() as MenuItem[], [account, products]);
  const routeConfig = useMemo(() => getRouteConfig(menus), [menus]);

  // 当staffID无效时跳转到登陆页面
  const staffID = useSelector((state: Store.State) =>
    Number(state.account.staff_id)
  );

  const dispatch = useDispatch();

  useEffect(() => {
    // user will be redirected new partner portal in TH region
    if (appRegion === 'th') {
      const [protocol, , host] = window.location.href.split('/');
      window.location.href = `${protocol}//${host
        .replace('business.', 'partner.')
        .replace('.shopeepay.', '.shopee.')}`;
      return;
    }
    dispatch(
      getGreyscale({
        top_merchant_id: account?.top_merchant_id,
        merchant_id: account?.merchant_id,
      })
    );
    dispatch(fetchStaffRelatedInfo());
    if (!staffID) {
      jumpLoginPage(lang || REGION_LANGUAGE || 'en');
    }
  }, [dispatch]);

  return staffID ? (
    // eslint-disable-next-line i18next/no-literal-string
    <ConfigProvider locale={antdLocale[lang || REGION_LANGUAGE || 'en']}>
      <Layout>
        <Switch>{routeConfig}</Switch>
      </Layout>
    </ConfigProvider>
  ) : null;
};

export default App;
