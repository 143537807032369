/* eslint-disable react-hooks/rules-of-hooks */
import React, { CSSProperties } from 'react';
import { Spin } from 'antd';
import style from './index.module.scss';

export default function Loading({
  styles = {},
  show = true,
  text = '',
}: {
  styles?: CSSProperties;
  show?: boolean;
  text?: string;
}) {
  return (
    <div
      className={style.loading}
      style={{ display: show ? 'flex' : 'none', ...styles }}
    >
      <Spin size="large" />
      {text && <span className={style.message}>{text}</span>}
    </div>
  );
}
