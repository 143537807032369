/* eslint-disable i18next/no-literal-string */
import { Product } from '@merchant-portal/shared/lib/product';
import { PERMISSIONS } from '@merchant-portal/shared/lib/typings';
enum DateType {
  'Today' = 'day',
  'This Week' = 'week',
  'This Month' = 'month',
  'Yesterday' = 'yesterday',
}

enum SearchType {
  local = 1,
  remote,
}

enum Lang_Type {
  'English' = 'en',
  'ไทย' = 'th',
  'Tiếng Việt' = 'vi',
}

//非收单产品
const Not_Acquiring_Products = [Product['Deal'], Product['Oauth']];

enum Greyscale_Result {
  GreyScaleResultNone = 0,
  GreyScaleResultToNewPortal = 1,
  GreyScaleResultToOldPortal = 2,
}

export {
  DateType,
  PERMISSIONS,
  Not_Acquiring_Products,
  SearchType,
  Lang_Type,
  Greyscale_Result,
};
