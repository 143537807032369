/* eslint-disable i18next/no-literal-string */
import { combineReducers } from 'redux';
import { message } from 'antd';
import Store from '../types/store';
import { BusinessType } from '../types';

import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import {
  getWebPortalGreyScale,
  postMerchants,
  postOutlets,
  postProducts,
} from '../utils/network';

import account from './account';
import { Greyscale_Result } from '../enums';

export const SET_BASIC_INFO = 'SET_BASIC_INFO';
export const LOGOUT = 'LOGOUT';
export const SET_OUTLETS = 'SET_OUTLETS';
export const SET_MERCHANTS = 'SET_MERCHANTS';
export const SET_PRODUCTS = 'SET_PRODUCTS';
export const SET_APPS = 'SET_APPS';
export const SET_GREYSCALE = 'SET_GREYSCALE';

export const MAX_PAGE_SIZE = 100;

// ================action sync ===================

export function setStaffInfo(data: IAccount) {
  return {
    type: SET_BASIC_INFO,
    data,
  };
}

export function setOutlets(data: Store.IOutlet) {
  return {
    type: SET_OUTLETS,
    data,
  };
}

export function setMerchants(data: Store.IMerchant) {
  return {
    type: SET_MERCHANTS,
    data,
  };
}

export function setProducts(data: Store.IProductItem[]) {
  return {
    type: SET_PRODUCTS,
    data,
  };
}

export function setApps(data: BusinessType.RefundConfig[]) {
  return {
    type: SET_APPS,
    data,
  };
}

// ================action async ===================

export function fetchOutlets(): ThunkAction<
  void,
  Store.State,
  unknown,
  Action
> {
  return async dispatch => {
    try {
      const data = await postOutlets({
        page_no: 1,
        page_size: MAX_PAGE_SIZE,
      });
      dispatch(setOutlets({ total: data.count, list: data.items }));
    } catch (e) {
      e.message && message.error(e.message);
    }
  };
}

export function fetchMerchants() {
  return async (dispatch: ThunkDispatch<Store.State, unknown, Action>) => {
    try {
      const data = await postMerchants({
        page_no: 1,
        page_size: MAX_PAGE_SIZE,
      });
      dispatch(setMerchants({ total: data.count, list: data.items }));
    } catch (e) {
      e.message && message.error(e.message);
    }
  };
}

export function fetchMerchantOrOutlets(): ThunkAction<
  void,
  Store.State,
  unknown,
  Action
> {
  return async function(dispatch, getState) {
    const {
      account: { top_merchant_id },
    } = getState();
    if (Number(top_merchant_id)) {
      await dispatch(fetchMerchants());
    } else {
      await dispatch(fetchOutlets());
    }
  };
}

export function fetchStaffProducts(): ThunkAction<
  void,
  Store.State,
  unknown,
  Action
> {
  return async function(dispatch) {
    try {
      const data = await postProducts();
      dispatch(setProducts(data));
    } catch (e) {
      e.message && message.error(e.message);
    }
  };
}

export function fetchStaffRelatedInfo(): ThunkAction<
  void,
  Store.State,
  unknown,
  Action
> {
  return function(dispatch) {
    return Promise.all([
      dispatch(fetchMerchantOrOutlets()),
      dispatch(fetchStaffProducts()),
    ]);
  };
}

// ================reducer ===================
const initOutlet = { total: 0, list: [] };
const outlet = (
  state: Store.IOutlet = initOutlet,
  action: Action & { data: Store.IOutlet }
) => {
  switch (action.type) {
    case SET_OUTLETS:
      return action.data;
    case LOGOUT:
      return initOutlet;
    default:
      return state;
  }
};

const isFetchOutlets = (state = false, action: Action) => {
  switch (action.type) {
    case SET_OUTLETS:
      return true;
    case LOGOUT:
      return false;
    default:
      return state;
  }
};

const initMerchant = { total: 0, list: [] };
const merchant = (
  state: Store.IMerchant = initMerchant,
  action: Action & { data: Store.IMerchant }
) => {
  switch (action.type) {
    case SET_MERCHANTS:
      return action.data;
    case LOGOUT:
      return initMerchant;
    default:
      return state;
  }
};

const products = (
  state: Store.IProductItem[] = [],
  action: Action & { data: Store.IProductItem[] }
) => {
  switch (action.type) {
    case SET_PRODUCTS:
      return action.data;
    case LOGOUT:
      return [];
    default:
      return state;
  }
};
// 判断 products 是否通过接口调用完成初始化
const isProductsInit = (state = false, action: Action) => {
  switch (action.type) {
    case SET_PRODUCTS:
      return true;
    case LOGOUT:
      return false;
    default:
      return state;
  }
};

const greyscaleData = (
  state: Store.IGreyscaleData = {
    result_code: 0,
    result_message: '',
    greyscale_result: Greyscale_Result.GreyScaleResultNone,
  },
  action: Action & { data: Store.IGreyscaleData }
) => {
  switch (action.type) {
    case SET_GREYSCALE:
      return action.data;
    default:
      return state;
  }
};
export function setGreyscale(data: Store.IGreyscaleData) {
  return {
    type: SET_GREYSCALE,
    data,
  };
}

export function getGreyscale(
  params: Pick<IAccount, 'top_merchant_id' | 'merchant_id'>
): ThunkAction<void, Store.State, unknown, Action> {
  return async function(dispatch) {
    try {
      const data = await getWebPortalGreyScale(params);
      // const data = {result_code: 1, result_message: 'Good', greyscale_result: Greyscale_Result.GreyScaleResultToNewPortal};
      dispatch(setGreyscale(data));
    } catch (e) {
      e.message && message.error(e.message);
      dispatch(
        setGreyscale({
          result_code: 0,
          result_message: '',
          greyscale_result: Greyscale_Result.GreyScaleResultToOldPortal,
        })
      );
    }
  };
}

export default combineReducers({
  account,
  outlet,
  merchant,
  isFetchOutlets,
  isProductsInit,
  products,
  greyscaleData,
});
