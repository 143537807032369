import React, { useEffect, useCallback, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Form, Input, Button, Card, Layout, message } from 'antd';
import style from './index.module.css';
import { useHistory } from 'react-router-dom';
import { loginThunk } from '../../store/account';
import { useView, useClick } from '../../hooks/track';
import CopyRight from '../../layouts/CopyRight';
import { getRefreshCode } from '../../utils/network';
import variable from '../../global.scss';
import i18next from 'i18next';
import config from '../../config';
import { useSelector } from 'react-redux';
import Store from '../../types/store';
import { isWeakPassword } from '../../utils/passwordCheck';

type Props = {
  otp?: string;
};

export const refreshSize = {
  width: 100,
  height: 30,
};

function Login({ otp }: Props) {
  const [verifyCodeHTML, setVerifyCodeHTML] = useState('');
  const [errText, setErrText] = useState('');
  const [isStrongPasswordPrompt, setIsStrongPasswordPrompt] = useState(false);

  const verifyCodeId = useRef('');
  const lang = useSelector((state: Store.State) => state.account.language);

  const [form] = Form.useForm();

  useView({ pageType: 'ap_ms_web_portal_login' });
  const reportClick = useClick({
    pageType: 'ap_ms_web_portal_login',
    targetType: 'sign_in_button',
  });

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    // 一进入页面就展示验证码
    refresh();
  }, []);

  const refresh = async () => {
    try {
      const data = await getRefreshCode(refreshSize);
      //更新验证码图片
      setVerifyCodeHTML(data.verify_code_value);
      //记录验证码id
      verifyCodeId.current = data.verify_code_id;
    } catch (e) {
      e.message && message.error(e.message);
    }
  };

  const reset = () => {
    form.resetFields();
    setIsStrongPasswordPrompt(false);
    setErrText('');
    refresh();
  };

  const jumpToChangePassword = () => {
    const username = form.getFieldValue('username');
    history.push('/changePassword', { username });
  };

  const onFormChange = useCallback(() => {
    if (isStrongPasswordPrompt) {
      setErrText('');
      refresh();
      setIsStrongPasswordPrompt(false);
    }
  }, [isStrongPasswordPrompt]);

  const onFinish = useCallback(
    async values => {
      reportClick();

      const { verify_code, password } = values;

      // 强密码校验

      if (isWeakPassword(password)) {
        setErrText(i18next.t('airpayms_login_password_failed'));
        setIsStrongPasswordPrompt(true);
        return;
      }

      verify_code &&
        (values.verify_code = {
          verify_code_id: verifyCodeId.current,
          verify_code_value: verify_code,
        });

      try {
        await dispatch(loginThunk(values));
        history.push('/');
      } catch (e) {
        // 验证码校验
        refresh();
        //设置错误提示
        setErrText(e.data?.msg);
      }
    },
    [dispatch, history, reportClick]
  );

  return (
    <Layout className={style.page}>
      <div
        style={{
          height: '80vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
        }}
      >
        <Card className="not-required" style={{ borderRadius: 5 }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {config.primaryLogoComponent}
            <span
              style={{
                fontSize: 20,
                color: variable.black87,
                marginLeft: 10,
              }}
            >
              {i18next.t(
                !config.isShopeePay && lang === 'en'
                  ? 'airpayms_airpay_merchant_portal'
                  : 'shopeepayms_airpay_merchant_portal'
              )}
            </span>
          </div>
          <div style={{ fontSize: 18, marginTop: 18, color: variable.black87 }}>
            {i18next.t('airpayms_sign_in_button')}
          </div>
          <Form
            layout="vertical"
            onFinish={onFinish}
            form={form}
            onChange={onFormChange}
          >
            <Form.Item
              label={i18next.t('airpayms_account')}
              name="username"
              rules={[
                { required: true, message: i18next.t('airpayms_name_require') },
              ]}
            >
              <Input placeholder={i18next.t('airpayms_account')} allowClear />
            </Form.Item>

            <Form.Item
              label={i18next.t('airpayms_password')}
              name="password"
              rules={[
                {
                  required: true,
                  message: i18next.t('airpayms_password_require'),
                },
              ]}
            >
              <Input.Password
                placeholder={i18next.t('airpayms_password')}
                allowClear
              />
            </Form.Item>

            {verifyCodeHTML ? (
              <div className={style.verifyLine}>
                <Form.Item
                  name="verify_code"
                  rules={[
                    {
                      required: true,
                      message: i18next.t('airpayms_code_require'),
                    },
                  ]}
                  style={{ flexGrow: 1 }}
                >
                  <Input
                    placeholder={i18next.t('airpayms_verify_code')}
                    allowClear
                  />
                </Form.Item>
                <div
                  dangerouslySetInnerHTML={{ __html: verifyCodeHTML }}
                  className="verify_img"
                  style={{ ...refreshSize, marginLeft: 10 }}
                  onClick={refresh}
                ></div>
              </div>
            ) : null}

            {errText && (
              <Form.Item>
                <div style={{ textAlign: 'center', color: variable.error }}>
                  {errText}
                </div>
              </Form.Item>
            )}

            {isStrongPasswordPrompt ? (
              <>
                <Button
                  disabled={!verifyCodeHTML}
                  type="primary"
                  onClick={jumpToChangePassword}
                  style={{ width: '100%' }}
                >
                  {i18next.t('airpayms_update_password_now')}
                </Button>
                <span className={style.reLoginText} onClick={reset}>
                  {i18next.t('airpayms_login_another_account')}
                </span>
              </>
            ) : (
              <Form.Item>
                <Button
                  disabled={!verifyCodeHTML}
                  type="primary"
                  htmlType="submit"
                  style={{ width: '100%' }}
                >
                  {i18next.t('airpayms_sign_in')}
                </Button>
              </Form.Item>
            )}
          </Form>
        </Card>
        <CopyRight style={{ alignItems: 'flex-end' }} />
      </div>
    </Layout>
  );
}

export default connect()(Login);
