import config from '../config/index';
/**
 * format money
 * example: 1234.123 => 1,234.12
 * @param money
 */
export const formatMoney = (money: string) => {
  if (!money) {
    return '';
  }
  let hasRadixPoint = money.includes('.') ? '.' : '';
  let [integerPart, decimalPart]: (string | undefined)[] = money.split('.');
  if (Number(integerPart) >= 1000 || Number(integerPart) <= -1000) {
    const reg = /(?!^)(?=(\d{3})+$)/g;
    integerPart = integerPart.replace(reg, config.amountSeparator!);
  }
  let moneyStr = integerPart + hasRadixPoint;
  if (decimalPart !== undefined) {
    moneyStr = [integerPart, decimalPart].join('.');
  }
  return moneyStr;
};
