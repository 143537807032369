import React from 'react';
import { appRegion } from '../utils/constants';
import variable from '../global.scss';
import { ReactComponent as WShopeePayLogo } from '../images/ic_airpay_w_shopee.svg';
import { ReactComponent as PrimaryShopeePayLogo } from '../images/ic_airpay_b_shopee.svg';

/* eslint-disable i18next/no-literal-string */
const defaultConfig = {
  timezone: '',
  currency: '',
  language: '',
  officeTel: '',
  officeEmail: '',
  showNotification: true,
  showVatWht: true,
  separatedVoucherMenu: true,
  requestRefundAmountPrecision: 0,
  showTaxInvoice: false,
  showWallet: true,
  isShopeePay: true,
  mainColor: variable.shopeePrimary,
  statisticContentColor: variable.shopeepayStatisticContent,
  wLogoComponent: <WShopeePayLogo />, //白色logo component
  primaryLogoComponent: <PrimaryShopeePayLogo />, // primary logo component
  amountSeparator: ',',
};
let config: Partial<typeof defaultConfig> = defaultConfig;
switch (appRegion) {
  case 'th':
    config = {
      ...defaultConfig,
      timezone: 'Asia/Bangkok',
      currency: '฿',
      language: 'th',
      officeTel: '021189155',
      officeEmail: 'help@shopeepay.co.th',
      showNotification: false,
      showVatWht: false,
      requestRefundAmountPrecision: 2,
      showTaxInvoice: true,
    };
    break;
  case 'vn':
    config = {
      ...defaultConfig,
      timezone: 'Asia/Ho_Chi_Minh',
      currency: '₫',
      language: 'vi',
      officeTel: '19006906',
      showVatWht: true,
      separatedVoucherMenu: false,
      amountSeparator: '.',
    };
    break;
  default:
    break;
}

export default config;

export const REGION_LANGUAGE = config.language;
