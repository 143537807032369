/* eslint-disable i18next/no-literal-string */
import React, { useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Input, Button, message } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { useClick } from '../../hooks/track';
import { changePwdThunk } from '../../store/account';
import { isWeakPassword } from '../../utils/passwordCheck';
import i18next from 'i18next';

function ChangePass() {
  const formRef = useRef<FormInstance | null>(null);

  const reportClick = useClick({
    pageType: 'ap_ms_web_portal_change_password',
    targetType: 'change_password_button',
  });

  const dispatch = useDispatch();

  const onFinish = useCallback(
    async values => {
      reportClick();
      try {
        await dispatch(changePwdThunk(values));
        message.success(
          i18next.t('airpayms_password_channge_successful'),
          () => {
            // hard refresh
            globalThis.location.href = '/login';
          }
        );
      } catch (e) {
        message.error(
          e.data?.msg || i18next.t('airpayms_current_password_not_match_error')
        );
      }
    },
    [dispatch, reportClick]
  );

  return (
    <div className="page-item">
      <h2>{i18next.t('airpayms_change_password')}</h2>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '80px',
        }}
      >
        <Form
          ref={formRef}
          layout="vertical"
          onFinish={onFinish}
          className="not-required"
          style={{ width: '300px' }}
        >
          <Form.Item
            label={i18next.t('airpayms_current_password')}
            name="old_password"
            rules={[
              {
                required: true,
                message: i18next.t('airpayms_enter_current_password'),
              },
            ]}
          >
            <Input.Password
              placeholder={i18next.t('airpayms_enter')}
              allowClear
            />
          </Form.Item>

          <Form.Item
            label={i18next.t('airpayms_new_password')}
            name="new_password"
            getValueFromEvent={event => {
              return event.target.value.replace(/\s+/g, '');
            }}
            rules={[
              () => ({
                validator(rule, value) {
                  if (!value) {
                    return Promise.reject(
                      i18next.t('airpayms_enter_new_password')
                    );
                  }
                  if (isWeakPassword(value)) {
                    return Promise.reject(
                      i18next.t('airpayms_changepass_password_failed')
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input.Password
              placeholder={i18next.t('airpayms_enter')}
              allowClear
            />
          </Form.Item>

          <Form.Item
            label={i18next.t('airpayms_confirm_password')}
            name="confirm_password"
            getValueFromEvent={event => {
              return event.target.value.replace(/\s+/g, '');
            }}
            rules={[
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value) {
                    return Promise.reject(
                      i18next.t('airpayms_enter_new password_again')
                    );
                  }
                  if (isWeakPassword(value)) {
                    return Promise.reject(
                      i18next.t('airpayms_changepass_password_failed')
                    );
                  }
                  if (getFieldValue('new_password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    i18next.t('airpayms_new_password_not_match_error')
                  );
                },
              }),
            ]}
          >
            <Input.Password
              placeholder={i18next.t('airpayms_enter')}
              allowClear
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
              {i18next.t('airpayms_change_password')}{' '}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default ChangePass;
