import React, { useEffect, useState } from 'react';
import { Layout, Card, message } from 'antd';
import { PaginationProps } from 'antd/lib/pagination';

import style from './index.module.scss';
import i18next from 'i18next';
import SearchLine from './SearchLine';
import Table from './Table';
import { getDateRange } from '../../../utils/time';
import AuthorizationGuard from '../../../components/AuthorizationGuard';
import { DateType, PERMISSIONS } from '../../../enums';
import transactionHistory from '../../../types/transactionHistory';
import {
  postTransactionHistory,
  postWalletBalance,
} from '../../../utils/network';
import { formatMoney } from '../../../utils/money';
import config from '../../../config';
import { appRegion } from '../../../utils/constants';

const initTimeType = DateType['This Month'];
const initTimeRange = getDateRange(initTimeType);

// 初始搜索条件
const initSearchParams: transactionHistory.ISearchParams = {
  time_range: initTimeRange,
};
const initSearchPage: transactionHistory.IPageParams = {
  page_no: 1,
  page_size: 10,
};

const TransactionHistory = () => {
  // table state
  const [searchParams, setSearchParams] = useState(initSearchParams);
  const [searchPage, setSearchPage] = useState(initSearchPage);
  const [tableData, setTableData] = useState([]);
  const [walletBalance, setWalletBalance] = useState('');
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    fetchWalletBalance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchPage]);

  const fetchWalletBalance = async () => {
    try {
      const data = await postWalletBalance();
      console.log('postWalletBalance:', data);
      setWalletBalance(formatMoney(data.walletBalance || 0));
    } catch (e) {
      e.message && message.error(e.message);
    }
  };

  const fetchTableData = async () => {
    setLoading(true);
    try {
      const data = await postTransactionHistory({
        ...searchPage,
        ...searchParams,
      });
      console.log('postTransactionHistory:', data);
      setTableData(data.merchant_wallet_history || []);
      setTotal(data.total);
    } catch (e) {
      setTableData([]);
      setTotal(0);
      e.message && message.error(e.message);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (pagination: PaginationProps) => {
    setSearchPage({
      page_no: pagination.current,
      page_size: pagination.pageSize,
    });
  };

  const handleSearch = (params: transactionHistory.ISearchParams) => {
    setSearchParams({
      ...params,
    });
    setSearchPage({ page_no: 1, page_size: searchPage.page_size });
  };

  return (
    <div className={`page-item`}>
      <h2>{i18next.t('airpayms_menu2_txn_history')}</h2>
      <Layout style={{ flexGrow: 0 }}>
        <AuthorizationGuard
          code={PERMISSIONS['WALLET.TRANSACTION_HISTORY.WALLET_BALANCE.VIEW']}
        >
          <Card className={style.cardItem}>
            <p className={style.balance}>
              <span className={style.title}>
                {i18next.t('airpayms_txn_history_balance')}:
              </span>
              {appRegion === 'th' ? (
                <>
                  <span className={style.currency}>{config.currency}</span>
                  <span className={style.money}>{walletBalance}</span>
                </>
              ) : (
                <>
                  <span className={style.money}>{walletBalance}</span>
                  <span className={style.currency}>{config.currency}</span>
                </>
              )}
            </p>
          </Card>
        </AuthorizationGuard>
        <AuthorizationGuard
          code={[
            PERMISSIONS[
              'WALLET.TRANSACTION_HISTORY.TRANSACTION_HISTORY.DOWNLOAD'
            ],
            PERMISSIONS['WALLET.TRANSACTION_HISTORY.TRANSACTION_HISTORY.VIEW'],
          ]}
        >
          <Card className={`${style.cardItem} ${style.summary}`}>
            <SearchLine onSearch={handleSearch} initTimeRange={initTimeRange} />
            <Table
              data={tableData}
              searchPage={{
                page_no: searchPage.page_no,
                page_size: searchPage.page_size,
              }}
              loading={loading}
              total={total}
              onPageChange={handlePageChange}
            />
          </Card>
        </AuthorizationGuard>
      </Layout>
    </div>
  );
};

export default TransactionHistory;
