import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { MenuItem } from '../layouts/menus/config';

interface Props extends RouteProps {
  component: React.FunctionComponent<{ menus?: MenuItem[] }>;
  menus?: MenuItem[];
}

// 包装Route组件，利用render 向component中传递menus props
const RouteWithMenus = ({ component: Component, menus, ...rest }: Props) => (
  <Route {...rest} render={props => <Component {...props} menus={menus} />} />
);

export default RouteWithMenus;
