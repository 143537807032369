/* eslint-disable i18next/no-literal-string */
import React, { useEffect, useState, useRef } from 'react';
import { message } from 'antd';
import { PaginationProps } from 'antd/lib/pagination';
import { FormInstance } from 'antd/lib/form';

import SearchLine from './SearchLine';
import Table from './Table';

import { getDateRange } from '../../../../utils/time';
import { postTransferHistory } from '../../../../utils/network';
import batchTransfer from '../../../../types/batchTransfer';
import { DateType } from '../../../../enums';

import style from '../index.module.scss';

const initTimeType = DateType['This Month'];
const initTimeRange = getDateRange(initTimeType);

// 初始搜索条件
const initSearchParams: batchTransfer.ISearchParams = {
  time_range: initTimeRange,
};
const initSearchPage: batchTransfer.IPageParams = {
  page_no: 1,
  page_size: 10,
};

const TransferHistory = () => {
  const [searchParams, setSearchParams] = useState(initSearchParams);
  const [searchPage, setSearchPage] = useState(initSearchPage);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);

  const formRef = useRef<FormInstance>(null);

  const fetchTableData = async () => {
    setLoading(true);
    try {
      const data = await postTransferHistory({
        ...searchPage,
        ...searchParams,
      });
      setTableData(data.payout_history || []);
      setTotal(data.total);
    } catch (e) {
      setTableData([]);
      setTotal(0);
      e.message && message.error(e.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (params: batchTransfer.ISearchParams) => {
    setSearchParams({
      ...params,
    });
    setSearchPage({ page_no: 1, page_size: searchPage.page_size });
  };

  const handlePageChange = (pagination: PaginationProps) => {
    setSearchPage({
      page_no: pagination.current,
      page_size: pagination.pageSize,
    });
  };

  useEffect(() => {
    fetchTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchPage]);

  return (
    <div className={style.tabItem}>
      <SearchLine
        onSearch={handleSearch}
        outerFormRef={formRef}
        initTimeRange={initTimeRange}
      ></SearchLine>
      <Table
        data={tableData}
        searchPage={{
          page_no: searchPage.page_no,
          page_size: searchPage.page_size,
        }}
        loading={loading}
        total={total}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default TransferHistory;
