import React from 'react';
import i18next from 'i18next';
import { Table } from 'antd';

import {
  TransferHistoryTableItem,
  displayColumns,
} from '@merchant-portal/shared/lib/wallet/transfer';
import batchTransfer from '../../../../types/batchTransfer';
import {
  WalletTransactionStatus,
  getWalletTransactionStatusMapText,
  getTransactionStatusStyle,
} from '@merchant-portal/shared/lib/wallet/helper';

const { Column } = Table;

type TransferHistoryTableProps = {
  data: TransferHistoryTableItem[];
  searchPage: batchTransfer.IPageParams;
  total: number;
  loading: boolean;
  onPageChange: batchTransfer.onPageChange;
};

const TransferHistoryTable: React.FC<TransferHistoryTableProps> = (
  props: TransferHistoryTableProps
) => {
  return (
    <div>
      <Table
        dataSource={props.data}
        scroll={{ x: 2000 }}
        pagination={{
          current: props.searchPage.page_no,
          pageSize: props.searchPage.page_size,
          total: props.total,
          showQuickJumper: true,
          showSizeChanger: true,
        }}
        onChange={props.onPageChange}
        loading={props.loading}
        rowKey="transaction_id"
      >
        {displayColumns
          .filter(item => {
            return item[0] !== 'status';
          })
          .map(([name, i18Key]) => {
            switch (name) {
              case 'status_int':
                return (
                  <Column
                    title={i18next.t(i18Key)}
                    key={name}
                    dataIndex={name}
                    render={(test, row: any, index) => {
                      return {
                        children: (
                          <span
                            style={{
                              color:
                                getTransactionStatusStyle[
                                  row.status_int as WalletTransactionStatus
                                ],
                            }}
                          >
                            {i18next.t(
                              getWalletTransactionStatusMapText[
                                row.status_int as WalletTransactionStatus
                              ]
                            )}
                          </span>
                        ),
                      };
                    }}
                  />
                );
              case 'memo':
                return (
                  <Column
                    title={i18next.t(i18Key)}
                    key={name}
                    dataIndex={name}
                    render={(test, row: any, index) => {
                      return {
                        children: (
                          <span
                            style={{
                              wordBreak: 'break-all',
                              wordWrap: 'break-word',
                            }}
                          >
                            {row.memo || ''}
                          </span>
                        ),
                      };
                    }}
                  />
                );
              default:
                return (
                  <Column
                    title={i18next.t(i18Key)}
                    dataIndex={name}
                    key={name}
                  />
                );
            }
          })}
      </Table>
    </div>
  );
};

export default TransferHistoryTable;
