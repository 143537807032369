export enum PWErrorType {
  TOO_SHORT = 1,
  NO_LOWER_CHAR,
  NO_HIGH_CHAR,
  NO_NUMBER_OR_SPECIAL_CHAR,
  EMPTY,
}

/**
 * 强密码校验
 * 10位及以上
 * 包含大小写字母
 * 数字和特殊字符至少有一种
 * @param password
 */
export const isWeakPassword = (password: string): PWErrorType | boolean => {
  if (!password) {
    return PWErrorType.EMPTY;
  }
  if (password.length < 10) {
    return PWErrorType.TOO_SHORT;
  }

  if (!/[a-z]/.test(password)) {
    return PWErrorType.NO_LOWER_CHAR;
  }

  if (!/[A-Z]/.test(password)) {
    return PWErrorType.NO_HIGH_CHAR;
  }

  if (!/[!@#~$%^&*()+|_0-9]/.test(password)) {
    return PWErrorType.NO_NUMBER_OR_SPECIAL_CHAR;
  }
  return false;
};
