import React from 'react';
import i18next from 'i18next';
import config from '../config';
import { appRegion } from '../utils/constants';

type CopyRightProps = {
  style?: Record<string, any>;
};

function CopyRight(props: CopyRightProps) {
  return (
    <div
      className="copy-right"
      style={{
        display: 'flex',
        flexDirection: 'column',
        fontSize: '14px',
        color: 'rgba(0, 0, 0, 0.38)',
        paddingLeft: '24px',
        ...props.style,
      }}
    >
      <span style={{ color: 'rgba(0,0,0,0.22)', marginBottom: 5 }}>
        {i18next.t('airpayms_contact')}
      </span>
      {appRegion === 'vn' ? <span>{config.officeTel}</span> : <></>}
      <span style={{ wordBreak: 'break-all' }}>{config.officeEmail}</span>
    </div>
  );
}

export default CopyRight;
