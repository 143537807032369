/* eslint-disable i18next/no-literal-string */
import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Input, Button, message } from 'antd';
import { useLocation, useHistory } from 'react-router-dom';
import { FormInstance } from 'antd/lib/form';
import { useClick } from '../../hooks/track';
import { changePwdNotLoginThunk } from '../../store/account';
import i18next from 'i18next';
import { isWeakPassword } from '../../utils/passwordCheck';
import { getRefreshCode } from '../../utils/network';
import { refreshSize } from '../login';

function ChangePassNotLogin() {
  const [username, setUsername] = useState('');
  const [verifyCodeHTML, setVerifyCodeHTML] = useState('');
  const verifyCodeId = useRef('');
  const formRef = useRef<FormInstance | null>(null);

  const reportClick = useClick({
    pageType: 'ap_ms_web_portal_change_password',
    targetType: 'change_password_button',
  });

  const dispatch = useDispatch();
  const history = useHistory<{ username: string }>();

  useEffect(() => {
    // 一进入页面就展示验证码

    const state = history.location.state;

    if (!state || !state.username) {
      history.replace('/login');
    }
    setUsername(state.username);
    refresh();
  }, []);

  const refresh = async () => {
    try {
      const data = await getRefreshCode(refreshSize);
      //更新验证码图片
      setVerifyCodeHTML(data.verify_code_value);
      //记录验证码id
      verifyCodeId.current = data.verify_code_id;
    } catch (e) {
      e.message && message.error(e.message);
    }
  };

  const onFinish = useCallback(
    async values => {
      reportClick();

      if (!username) {
        message.error('account name missing!', 3, () => {
          history.replace('/login');
        });
        return;
      }

      values.username = username;

      const { verify_code } = values;

      verify_code &&
        (values.verify_code = {
          verify_code_id: verifyCodeId.current,
          verify_code_value: verify_code,
        });

      try {
        await dispatch(changePwdNotLoginThunk(values));
        message.success(
          i18next.t('airpayms_password_channge_successful'),
          () => {
            // hard refresh
            globalThis.location.href = '/login';
          }
        );
      } catch (e) {
        // 验证码校验
        refresh();
        message.error(
          e.data?.msg || i18next.t('airpayms_current_password_not_match_error')
        );
      }
    },
    [dispatch, reportClick, username]
  );

  return (
    <div className="page-item" style={{ padding: '40px 300px' }}>
      <div>
        <h2>{i18next.t('airpayms_change_password')}</h2>
        <p style={{ margin: 0 }}>{i18next.t('airpayms_password_notice_msg')}</p>
        <p style={{ margin: 0 }}>
          {i18next.t('airpayms_password_notice_msg2')}
        </p>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '80px',
        }}
      >
        <Form
          ref={formRef}
          layout="vertical"
          onFinish={onFinish}
          className="not-required"
          style={{ width: '300px' }}
        >
          <Form.Item
            label={i18next.t('airpayms_current_password')}
            name="old_password"
            rules={[
              {
                required: true,
                message: i18next.t('airpayms_enter_current_password'),
              },
            ]}
          >
            <Input.Password
              placeholder={i18next.t('airpayms_enter')}
              allowClear
            />
          </Form.Item>

          <Form.Item
            label={i18next.t('airpayms_new_password')}
            name="new_password"
            getValueFromEvent={event => {
              return event.target.value.replace(/\s+/g, '');
            }}
            rules={[
              () => ({
                validator(rule, value) {
                  if (!value) {
                    return Promise.reject(
                      i18next.t('airpayms_enter_new_password')
                    );
                  }
                  if (isWeakPassword(value)) {
                    return Promise.reject(
                      i18next.t('airpayms_changepass_password_failed')
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input.Password
              placeholder={i18next.t('airpayms_enter')}
              allowClear
            />
          </Form.Item>

          <Form.Item
            label={i18next.t('airpayms_confirm_password')}
            name="confirm_password"
            getValueFromEvent={event => {
              return event.target.value.replace(/\s+/g, '');
            }}
            rules={[
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value) {
                    return Promise.reject(
                      i18next.t('airpayms_enter_new password_again')
                    );
                  }
                  if (isWeakPassword(value)) {
                    return Promise.reject(
                      i18next.t('airpayms_changepass_password_failed')
                    );
                  }
                  if (getFieldValue('new_password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    i18next.t('airpayms_new_password_not_match_error')
                  );
                },
              }),
            ]}
          >
            <Input.Password
              placeholder={i18next.t('airpayms_enter')}
              allowClear
            />
          </Form.Item>

          {verifyCodeHTML ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'self-start',
                justifyContent: 'space-between',
              }}
            >
              <Form.Item
                name="verify_code"
                rules={[
                  {
                    required: true,
                    message: i18next.t('airpayms_code_require'),
                  },
                ]}
                style={{ flexGrow: 1 }}
              >
                <Input
                  placeholder={i18next.t('airpayms_verify_code')}
                  allowClear
                />
              </Form.Item>
              <div
                dangerouslySetInnerHTML={{ __html: verifyCodeHTML }}
                className="verify_img"
                style={{ ...refreshSize, marginLeft: 10 }}
                onClick={refresh}
              ></div>
            </div>
          ) : null}

          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
              {i18next.t('airpayms_change_password')}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default ChangePassNotLogin;
