import React from 'react';

import { Table } from 'antd';
import transactionHistory from '../../../types/transactionHistory';

import {
  displayColumns,
  TransactionHistoryTableItem,
} from '@merchant-portal/shared/lib/wallet/transactionHistory';
import {
  WalletTransactionStatus,
  getWalletTransactionStatusMapText,
  getTransactionStatusStyle,
  WalletTransactionType,
  getWalletTransactionTypeMapText,
} from '@merchant-portal/shared/lib/wallet/helper';

import i18next from 'i18next';

const { Column } = Table;

type TransactionTableProps = {
  data: TransactionHistoryTableItem[];
  searchPage: transactionHistory.IPageParams;
  total: number;
  loading: boolean;
  onPageChange: transactionHistory.onPageChange;
};

const TransactionTable: React.FC<TransactionTableProps> = (
  props: TransactionTableProps
) => {
  return (
    <div>
      <Table
        dataSource={props.data}
        pagination={{
          current: props.searchPage.page_no,
          pageSize: props.searchPage.page_size,
          total: props.total,
          showQuickJumper: true,
          showSizeChanger: true,
        }}
        onChange={props.onPageChange}
        loading={props.loading}
        rowKey="transaction_id"
      >
        {displayColumns
          .filter(item => {
            return item[0] !== 'status';
          })
          .map(([name, i18Key, width]) => {
            switch (name) {
              case 'status_int':
                return (
                  <Column
                    title={i18next.t(i18Key)}
                    key={name}
                    dataIndex={name}
                    width={width}
                    render={(test, row: any, index) => {
                      return {
                        children: (
                          <span
                            style={{
                              color:
                                getTransactionStatusStyle[
                                  row.status_int as WalletTransactionStatus
                                ],
                            }}
                          >
                            {i18next.t(
                              getWalletTransactionStatusMapText[
                                row.status_int as WalletTransactionStatus
                              ]
                            )}
                          </span>
                        ),
                      };
                    }}
                  />
                );
              case 'txn_type_int':
                return (
                  <Column
                    title={i18next.t(i18Key)}
                    key={name}
                    dataIndex={name}
                    width={width}
                    render={(test, row: any, index) => {
                      return {
                        children: (
                          <span>
                            {i18next.t(
                              getWalletTransactionTypeMapText[
                                row.txn_type_int as WalletTransactionType
                              ]
                            )}
                          </span>
                        ),
                      };
                    }}
                  />
                );
              case 'memo':
                return (
                  <Column
                    title={i18next.t(i18Key)}
                    key={name}
                    dataIndex={name}
                    width={width}
                    render={(test, row: any, index) => {
                      return {
                        children: (
                          <span
                            style={{
                              wordBreak: 'break-all',
                              wordWrap: 'break-word',
                            }}
                          >
                            {row.memo || ''}
                          </span>
                        ),
                      };
                    }}
                  />
                );
              default:
                return (
                  <Column
                    title={i18next.t(i18Key)}
                    dataIndex={name}
                    key={name}
                    width={width}
                  />
                );
            }
          })}
      </Table>
    </div>
  );
};

export default TransactionTable;
