import React, { useRef, useCallback, useMemo } from 'react';
import moment from 'moment';
import qs from 'query-string';
import _ from 'lodash';
import i18next from 'i18next';
import { Form, Button, Input, Select } from 'antd';
import { FormInstance } from 'antd/lib/form';

import {
  WalletTransactionStatus,
  getWalletTransactionStatusMapText,
} from '@merchant-portal/shared/lib/wallet/helper';

import commonStyle from '../../../../components/business/searchLine/index.module.css';
import Download from '../../../../components/business/download';
import AuthorizationGuard from '../../../../components/AuthorizationGuard';
import DateSelect from '../../../../components/business/searchLine/DateSelect';

import { getTimestampFromTimeRange } from '../../../../utils/time';
import { getDateRange, getRegionMoment } from '../../../../utils/time';
import { enum2SelectList } from '../../../../utils';
import { DateType, PERMISSIONS } from '../../../../enums';

import style from '../index.module.scss';

const { Option } = Select;

const transferStatusList = enum2SelectList(WalletTransactionStatus);

export type BasicSearch = {
  merchant_id?: string;
  outlet_id?: string;
  time_range?: moment.Moment[];
};

interface SearchLineProps {
  initTimeRange?: moment.Moment[];
  onSearch: Function;
  outerFormRef?: React.RefObject<FormInstance>;
  from?: string;
}

const SearchLine = (props: SearchLineProps) => {
  const localFormRef = useRef<FormInstance>(null);

  const initialValues = useMemo(
    () => ({
      time_range: props.initTimeRange || getDateRange(DateType['Today']),
    }),
    [props.initTimeRange]
  );

  const finalRef = useMemo(() => {
    return props.outerFormRef || localFormRef;
  }, [props.outerFormRef]);

  const validateShopeepayOrderId = (values: any) => {
    if (
      values.shopeepay_order_id &&
      (isNaN(Number(values.shopeepay_order_id)) ||
        Number(values.shopeepay_order_id) < 0)
    ) {
      finalRef.current?.setFieldsValue({
        shopeepay_order_id: undefined,
      });
      delete values.shopeepay_order_id;
    }
  };

  const onFinish = useCallback(
    (values: any) => {
      validateShopeepayOrderId(values);
      props.onSearch(values);
    },
    [props]
  );

  // 手动更新日期组件
  const handleDataRangeChange = useCallback(
    (dates: any, dateStrings: string[]) => {
      (finalRef.current as FormInstance).setFieldsValue({
        time_type: undefined,
      });
      (finalRef.current as FormInstance).setFieldsValue({
        time_range: getRegionMoment(dateStrings),
      });
    },
    [finalRef]
  );

  const handleValidNumber = (event: any) => {
    const isNumber = /[\d]/.test(String.fromCharCode(event.charCode));
    if (!isNumber) {
      event.preventDefault();
    }
  };

  const handleDownload = useCallback(type => {
    finalRef.current?.validateFields().then(values => {
      const [start_time, end_time] = getTimestampFromTimeRange(
        values.time_range
      );

      validateShopeepayOrderId(values);

      values.start_time = start_time;
      values.end_time = end_time;

      // 删除掉后端不需要的参数
      delete values.time_range;

      window.location.href = `/api/wallet/transferHistory/download/${type}?${qs.stringify(
        _.pickBy(values, _.identity)
      )}`;
    });
  }, []);

  const reset = useCallback(() => {
    // 重置表单
    (finalRef.current as FormInstance).resetFields();

    //重置数据
    finalRef.current?.validateFields().then(values => {
      props.onSearch(values, true);
    });
  }, [finalRef, props]);

  return (
    <div
      className={`${commonStyle.searchLine} ${style.searchLine}`}
      style={{ marginBottom: '20px' }}
    >
      <AuthorizationGuard
        code={PERMISSIONS['WALLET.BATCH_TRANSFER.TRANSFER_HISTORY.DOWNLOAD']}
      >
        <Download
          onDownload={handleDownload}
          className={style.download}
          title={`${i18next.t('airpayms_tab_batch_transfer')} ${i18next.t(
            'airpayms_report'
          )}`}
        />
      </AuthorizationGuard>
      <Form
        ref={finalRef}
        initialValues={initialValues}
        layout="vertical"
        onFinish={onFinish}
        className={commonStyle.form}
      >
        <div style={{ flexWrap: 'wrap' }}>
          <DateSelect
            type={'date'}
            label={`${i18next.t('airpayms_create_date_filter')}:`}
            onRangeChange={handleDataRangeChange}
            from={props.from}
            limitTimeRange={{ days: 60 }}
          />
          <Form.Item
            label={i18next.t('airpayms_transfer_history_txn_id')}
            name="transaction_id"
          >
            <Input
              type={'number'}
              placeholder={`${i18next.t(
                'airpayms_input_box_input'
              )} ${i18next.t('airpayms_transfer_history_txn_id')}`}
              style={{ width: '200px' }}
              onKeyPressCapture={handleValidNumber}
            />
          </Form.Item>
          <Form.Item
            label={i18next.t('airpayms_transfer_history_phone_no')}
            name="user_phone_num"
          >
            <Input
              placeholder={`${i18next.t(
                'airpayms_input_box_input'
              )} ${i18next.t('airpayms_transfer_history_phone_no')}`}
              style={{ width: '200px' }}
            />
          </Form.Item>
          <Form.Item label={i18next.t('airpayms_voucher_status')} name="status">
            <Select
              placeholder={`${i18next.t(
                'airpayms_dropdown_box_select'
              )} ${i18next.t('airpayms_voucher_status')}`}
              style={{ width: '140px' }}
              allowClear
            >
              {transferStatusList.map(status => {
                return (
                  <Option value={status.value} key={status.value}>
                    {i18next.t(
                      getWalletTransactionStatusMapText[
                        status.value as WalletTransactionStatus
                      ]
                    )}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label={i18next.t('airpayms_head_merchant_order_id')}
            name="ex_merchant_ref"
          >
            <Input
              placeholder={`${i18next.t(
                'airpayms_input_box_input'
              )} ${i18next.t('airpayms_head_merchant_order_id')}`}
              style={{ width: '200px' }}
            />
          </Form.Item>
          <Form.Item
            label={i18next.t('shoeepayms_filter_shopeepay_order_id')}
            name="shopeepay_order_id"
          >
            <Input
              type={'number'}
              placeholder={`${i18next.t(
                'airpayms_input_box_input'
              )} ${i18next.t('shoeepayms_filter_shopeepay_order_id')}`}
              style={{ width: '250px' }}
              onKeyPressCapture={handleValidNumber}
            />
          </Form.Item>
          <Form.Item label="    ">
            <Button
              type="primary"
              htmlType="submit"
              style={{ padding: '5px 37px' }}
            >
              {i18next.t('airpayms_search_button')}
            </Button>
          </Form.Item>
          <Form.Item label="    ">
            <Button onClick={reset}>
              {i18next.t('airpayms_reset_button')}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default SearchLine;
