/* eslint-disable i18next/no-literal-string */
import { createTrackingV3Sdk } from '@shopee/trackingv3sdk';
import fingerprintjs2 from 'fingerprintjs2';
import { appEnv, appRegion } from './constants';
import { v4 as uuid } from 'uuid';

const APP_ID = 8;

const getEnv = () => {
  switch (appEnv) {
    case 'livestaging':
      return 'uat';
    case 'uat':
    case 'uatnonlive':
      return 'staging';
    default:
      return appEnv;
  }
};
const trackEnv = getEnv();

/**
 * use finger print or random uuid as device id
 */
const qUuid: Promise<string> =
  process.env.NODE_ENV === 'test'
    ? Promise.resolve('')
    : Promise.race([
        new Promise<string>(resolve => {
          fingerprintjs2.getV18((hash: string, components) => {
            resolve(hash);
          });
        }),
        new Promise<string>(
          resolve =>
            //  超时的话，使用使用时间戳
            setTimeout(() => resolve(uuid()), 2000) // 2s
        ),
      ]);

let deviceId = '';
qUuid.then((hash: string) => {
  deviceId = hash;
});

const globalTracker = createTrackingV3Sdk({
  env: trackEnv,
  locale: appRegion,
  platform: 'pc',
  source: 'pc',
  enableAutoTrack: false,
  meta: {
    // userid: ?number,
  },
  getCookies() {
    return {
      SPC_F: deviceId,
    };
  },
  // sendEventByInterval: true,
  ua: navigator.userAgent,
  fetch: (url: string, options?: any) => globalThis.fetch(url, options),
  appId: APP_ID,
});

const oldSync = globalTracker.sync;
// waiting for finger print response
globalTracker.sync = async function(...args: any[]) {
  await qUuid;
  oldSync.call(this, ...args);
};

export default globalTracker;
