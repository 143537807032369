const s3RedirectBaseUrl = '/api/wallet/S3Redirect?id=';

const iframeDownload = function(url: any) {
  const iframeElement = document.createElement('iframe');
  iframeElement.style.display = 'none';
  iframeElement.setAttribute('src', url);
  document.body.appendChild(iframeElement);
  setTimeout(() => {
    iframeElement.remove();
  }, 10000);
};

export { iframeDownload, s3RedirectBaseUrl };
