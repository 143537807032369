import { Tabs } from 'antd';
import i18next from 'i18next';
import React from 'react';
import style from './index.module.scss';
import History from './history';
import Transfer from './transfer';
import AuthorizationGuard from '../../../components/AuthorizationGuard';
import { PERMISSIONS } from '../../../enums';

const { TabPane } = Tabs;

const BatchTransferPage = () => {
  const transferEl = AuthorizationGuard({
    code: [
      PERMISSIONS['WALLET.BATCH_TRANSFER.TRANSFER'],
      PERMISSIONS['WALLET.BATCH_TRANSFER.BATCH_TRANSFER_LOG.VIEW'],
    ],
    children: (
      <TabPane
        tab={i18next.t('airpayms_tab_batch_transfer')}
        key="batch_transfer"
      >
        <Transfer />
      </TabPane>
    ),
  });
  const historyEl = AuthorizationGuard({
    code: PERMISSIONS['WALLET.BATCH_TRANSFER.TRANSFER_HISTORY.VIEW'],
    children: (
      <TabPane
        tab={i18next.t('airpayms_tab_transfer_history')}
        key="transfer_history"
      >
        <History />
      </TabPane>
    ),
  });

  const tabsEl = (
    <Tabs>
      {transferEl}
      {historyEl}
    </Tabs>
  );

  return (
    <div className={`page-item white-bg ${style.batchTransfer}`}>
      <h2>{i18next.t('airpayms_menu2_transfer_history')}</h2>
      {tabsEl}
    </div>
  );
};

export default BatchTransferPage;
