"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.displayColumns = [
    ['operating_time', 'airpayms_file_submit_time', 200],
    ['operator', 'airpayms_operator', 200],
    ['file_name', 'airpayms_file_submit_name', 500],
    ['total', 'airpayms_file_submit_total_count', 150],
    ['total_succ', 'airpayms_file_submit_success_count', 150],
    ['batch_status', 'airpayms_voucher_status', 150],
    ['remarks', 'airpayms_transfer_log_remark'],
    ['completion_time', 'airpayms_file_submit_completion_time', 200],
    ['feedback', 'airpayms_file_submit_feedback', 200],
];
exports.feedbackColumns = [
    ['move_to_phone', 'airpayms_transfer_history_phone_no'],
    ['transaction_amount', 'airpayms_transfer_history_amount'],
    ['transfer_desc', 'airpayms_transfer_history_description'],
    ['memo', 'airpayms_transfer_log_remark'],
    ['status', 'airpayms_transfer_feedback_result'],
    ['err_msg', 'airpayms_transfer_feedback_description'],
    ['transaction_id', 'airpayms_transfer_history_txn_id'],
    ['shopeepay_order_id', 'shoeepayms_filter_shopeepay_order_id'],
];
