import React, {
  useMemo,
  useState,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { Form, Select, message } from 'antd';
import { useClick } from '../../../hooks/track';
import APASelect from '../../../components/APASelect';

import { MAX_PAGE_SIZE } from '../../../store/main';
import { postMerchants } from '../../../utils/network';
import { SearchType } from '../../../enums';
import Store from '../../../types/store';
import i18next from 'i18next';
const { Option } = Select;

type MerChantSelectPropsType = {
  onChange: Function;
  formItemLabel?: string;
  formItemName?: string;
  from?: string;
};

const targetType = 'merchant_filter';

const MerchantSelect: React.FC<MerChantSelectPropsType> = (
  props: MerChantSelectPropsType
) => {
  const storeTotal = useSelector((state: Store.State) => state.merchant.total);
  const storeList = useSelector((state: Store.State) => state.merchant.list);

  const [list, setList] = useState(storeList);
  const [total, setTotal] = useState(storeTotal);
  const searchParams = useRef({ name: '', page_no: 1 });

  // state与store同步
  useEffect(() => {
    setList(storeList);
    setTotal(storeTotal);
  }, [storeTotal, storeList]);

  const searchType = useMemo(() => {
    if (storeTotal > MAX_PAGE_SIZE) {
      return SearchType['remote'];
    } else {
      return SearchType['local'];
    }
  }, [storeTotal]);

  const fetchData = useCallback(async () => {
    const { name, page_no } = searchParams.current;

    try {
      const data = await postMerchants(
        _.pickBy(
          {
            name: name,
            page_no,
            page_size: MAX_PAGE_SIZE,
          },
          _.identity
        )
      );
      setList(list =>
        page_no === 1 ? [...data.items] : [...list, ...data.items]
      );
      setTotal(data.count);
    } catch (e) {
      setList([]);
      setTotal(0);
      e.message && message.error(e.message);
    }
  }, []);

  const handleSearch = useCallback(
    (value: string) => {
      searchParams.current = { name: value, page_no: 1 };
      fetchData();
    },
    [fetchData]
  );

  const handleScrollToBottom = useCallback(() => {
    const { name, page_no } = searchParams.current;

    const maxPage = Math.ceil(total / MAX_PAGE_SIZE);
    if (page_no >= maxPage) {
      return;
    }
    searchParams.current = { name, page_no: page_no + 1 };
    fetchData();
  }, [total, fetchData]);

  const reportClick = useClick({
    pageType: props.from || '',
    targetType,
  });

  const handleChange = useCallback(
    value => {
      reportClick();
      props.onChange(value);
    },
    [reportClick, props]
  );

  if (!storeTotal || storeTotal < 1) {
    return null;
  }

  const options =
    list &&
    list.map(merchant => {
      return (
        <Option value={merchant.id} key={merchant.id}>
          {merchant.name}
        </Option>
      );
    });

  const selectEl =
    searchType === SearchType['local'] ? (
      <APASelect
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) =>
          option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        style={{ width: '190px' }}
        placeholder={i18next.t('airpayms_select_merchant')}
        onChange={handleChange}
        pageType={props.from}
        targetType={targetType}
        allowClear
      >
        {options}
      </APASelect>
    ) : (
      <APASelect
        showSearch
        filterOption={false}
        onChange={handleChange}
        onSearch={_.debounce(handleSearch, 800)}
        onScrollToBottom={handleScrollToBottom}
        style={{ width: '190px' }}
        placeholder={i18next.t('airpayms_select_merchant')}
        pageType={props.from}
        targetType={targetType}
        allowClear
      >
        {options}
      </APASelect>
    );

  const formItemEl = (
    <Form.Item
      label={props.formItemLabel || i18next.t('airpayms_filter_merchant')}
      name={props.formItemName || 'merchant_id'}
    >
      {selectEl}
    </Form.Item>
  );

  return formItemEl;
};

export default MerchantSelect;
