import React, { useState, useRef, useCallback, useMemo } from 'react';

import { useClick } from '../../../hooks/track';

import { Form, Button } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';

import { FormInstance } from 'antd/lib/form';
import MerchantSelect from './MerchantSelect';
import OutletSelect from './OutletSelect';
import DateSelect from './DateSelect';
import { DateType } from '../../../enums';

import { getDateRange, getRegionMoment, TimeType } from '../../../utils/time';

import style from './index.module.css';
import moment from 'moment';
import i18next from 'i18next';
import config from '../../../config';

export type BasicSearch = {
  merchant_id?: string;
  outlet_id?: string;
  time_range?: moment.Moment[];
};

interface SearchLineProps {
  onSearch: Function;
  dateType?: TimeType;
  dateBeforeCom?: React.ReactElement | React.ComponentType;
  nextLineCom?: React.ReactElement | React.ComponentType;
  initTimeRange?: moment.Moment[];
  outerFormRef?: React.RefObject<FormInstance>;
  from?: string;
  hideOutlets?: boolean;
  hideMerchants?: boolean;
  datelabel?: string;
}

const SearchLine = (props: SearchLineProps) => {
  const [isShowNextLine, setIsShowNextLine] = useState(false);

  const [outerMerchantId, setOuterMerchantId] = useState<
    number | string | undefined
  >(undefined);

  const localFormRef = useRef<FormInstance>(null);

  const applyReport = useClick({
    pageType: props.from || '',
    targetType: 'apply_button',
  });

  const resetReport = useClick({
    pageType: props.from || '',
    targetType: 'reset_button',
  });

  const advancedReport = useClick({
    pageType: props.from || '',
    targetType: 'advance_filter_button',
  });

  const initialValues = useMemo(
    () => ({
      time_range: props.initTimeRange || getDateRange(DateType['This Month']),
    }),
    [props.initTimeRange]
  );

  const finalRef = useMemo(() => {
    return props.outerFormRef || localFormRef;
  }, [props.outerFormRef]);

  const handleMerchantChange = useCallback(
    (value: number | string) => {
      // 标准商户变更，重置门店值
      (finalRef.current as FormInstance).setFieldsValue({
        outlet_id: undefined,
      });
      setOuterMerchantId(value);
    },
    [finalRef]
  );

  const onFinish = useCallback(
    (values: any) => {
      applyReport();
      props.onSearch(values);
    },
    [applyReport, props]
  );

  // 手动更新日期组件
  const handleDataRangeChange = useCallback(
    (dates: moment.Moment[], dateStrings: string[]) => {
      (finalRef.current as FormInstance).setFieldsValue({
        time_range: getRegionMoment(dateStrings),
      });
    },
    [finalRef]
  );

  const reset = useCallback(() => {
    // 上报埋点
    resetReport();
    // 重置表单
    (finalRef.current as FormInstance).resetFields();
    // 重置门店下拉框。账号绑定在顶层商户下，只有先选择了标准商户才能选择门店，不能跨过标准商户直接选门店
    setOuterMerchantId(undefined);

    //重置数据
    finalRef.current?.validateFields().then(values => {
      props.onSearch(values, true);
    });
  }, [resetReport, finalRef, props]);

  const toggleNextLine = useCallback(() => {
    advancedReport();
    setIsShowNextLine(!isShowNextLine);
  }, [advancedReport, isShowNextLine]);

  return (
    <div className={style.searchLine}>
      <Form
        ref={finalRef}
        initialValues={initialValues}
        layout="vertical"
        onFinish={onFinish}
        className={style.form}
      >
        <div style={{ flexWrap: 'wrap' }}>
          {!props.hideMerchants && (
            <MerchantSelect onChange={handleMerchantChange} from={props.from} />
          )}
          {!props.hideOutlets && (
            <OutletSelect
              outer_merchant_id={outerMerchantId}
              from={props.from}
            />
          )}
          {props.dateBeforeCom}
          <DateSelect
            label={props.datelabel}
            type={props.dateType}
            onRangeChange={handleDataRangeChange}
            from={props.from}
          />
          <Form.Item label="    ">
            <Button type="primary" htmlType="submit">
              {i18next.t('airpayms_tab_apply')}
            </Button>
          </Form.Item>
          <Form.Item label="    ">
            <Button onClick={reset}>
              {i18next.t('airpayms_reset_button')}
            </Button>
          </Form.Item>

          {props.nextLineCom ? (
            <Form.Item label="    ">
              <span
                onClick={toggleNextLine}
                className={style.advanced}
                style={{ color: config.mainColor }}
              >
                {i18next.t('airpayms_filter_advanced')}{' '}
                {isShowNextLine ? <UpOutlined /> : <DownOutlined />}
              </span>
            </Form.Item>
          ) : null}
        </div>
        <div
          className={`${style.nextLine} ${isShowNextLine ? 'show' : 'hide'}`}
        >
          {props.nextLineCom}
        </div>
      </Form>
    </div>
  );
};

export default SearchLine;
