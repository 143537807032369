import React from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import i18next from 'i18next';
import variable from '../../../global.scss';
import { Popover } from 'antd';
import config from '../../../config';

type DownloadProps = {
  onDownload: (type: string) => void;
  className?: string;
  style?: Object;
  title: string;
};

// eslint-disable-next-line i18next/no-literal-string
const Download_Type = ['xlsx', 'csv', 'pdf'];

const Download = (props: DownloadProps) => {
  const content = (
    <div>
      <div>{props.title}</div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          border: '1px solid #D9D9D9',
        }}
      >
        {Download_Type.map((type, index) => (
          <span
            className={
              config.isShopeePay
                ? 'shopee-hover-with-primary'
                : 'hover-with-primary'
            }
            onClick={() => props.onDownload(type)}
            key={type}
            style={{
              cursor: 'pointer',
              padding: '5px 0',
              flexGrow: 1,
              textAlign: 'center',
              borderLeft: index === 0 ? 'none' : '1px solid #D9D9D9',
            }}
          >
            {type}
          </span>
        ))}
      </div>
    </div>
  );
  return (
    <Popover content={content} placement="bottomRight">
      <div
        className={props.className}
        style={{
          color: config.mainColor,
          cursor: 'pointer',
          fontSize: 12,
          ...props.style,
        }}
      >
        <span style={{ marginRight: 10 }}>
          {i18next.t('airpayms_button_download')}
        </span>
        <DownloadOutlined />
      </div>
    </Popover>
  );
};

export default Download;
