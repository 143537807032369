"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var WalletTransactionType;
(function (WalletTransactionType) {
    WalletTransactionType[WalletTransactionType["Refund"] = 120201] = "Refund";
    WalletTransactionType[WalletTransactionType["Withdraw"] = 130101] = "Withdraw";
    WalletTransactionType[WalletTransactionType["MerchantAdjustmentAdd"] = 140101] = "MerchantAdjustmentAdd";
    WalletTransactionType[WalletTransactionType["MerchantAdjustmentDeduct"] = 150101] = "MerchantAdjustmentDeduct";
    WalletTransactionType[WalletTransactionType["Settlement"] = 180201] = "Settlement";
    WalletTransactionType[WalletTransactionType["PayoutToBank"] = 190101] = "PayoutToBank";
    WalletTransactionType[WalletTransactionType["PayoutToWallet"] = 200101] = "PayoutToWallet";
})(WalletTransactionType = exports.WalletTransactionType || (exports.WalletTransactionType = {}));
/**
 * 如果交易类型为Adjust Add或Settlement，则为+号。如果交易类型为Adjust Deduct、Refund、Withdrawal、Pay to Bank或Pay to Wallet，则为-号
 */
function addSymbolsWithTransactionType(money, type) {
    if (!money || !Number(money)) {
        return '0';
    }
    const isDeduct = [
        WalletTransactionType.MerchantAdjustmentDeduct,
        WalletTransactionType.MerchantAdjustmentDeduct,
        WalletTransactionType.Refund,
        WalletTransactionType.Withdraw,
        WalletTransactionType.PayoutToBank,
        WalletTransactionType.PayoutToWallet,
    ].some(t => t === type);
    return isDeduct ? `-${money}` : `+${money}`;
}
exports.addSymbolsWithTransactionType = addSymbolsWithTransactionType;
// 对应的国际化
exports.getWalletTransactionTypeMapText = {
    [WalletTransactionType.Refund]: 'airpay_tab_refund',
    [WalletTransactionType.Withdraw]: 'airpayms_txn_type_withdrawal',
    [WalletTransactionType.MerchantAdjustmentAdd]: 'airpayms_txn_type_adjust_add',
    [WalletTransactionType.MerchantAdjustmentDeduct]: 'airpayms_txn_type_adjust_deduct',
    [WalletTransactionType.Settlement]: 'airpayms_txn_type_settlement',
    [WalletTransactionType.PayoutToBank]: 'airpayms_txn_type_pay2bank',
    [WalletTransactionType.PayoutToWallet]: 'airpayms_txn_type_pay2wallet',
};
// 交易状态
var WalletTransactionStatus;
(function (WalletTransactionStatus) {
    WalletTransactionStatus[WalletTransactionStatus["Processing"] = 2] = "Processing";
    WalletTransactionStatus[WalletTransactionStatus["Success"] = 3] = "Success";
    WalletTransactionStatus[WalletTransactionStatus["Failed"] = 4] = "Failed";
})(WalletTransactionStatus = exports.WalletTransactionStatus || (exports.WalletTransactionStatus = {}));
/** Transaction */
// 交易状态 对应的国际化
exports.getWalletTransactionStatusMapText = {
    [WalletTransactionStatus.Processing]: 'airpayms_status_processing',
    [WalletTransactionStatus.Success]: 'airpayms_status_successful',
    [WalletTransactionStatus.Failed]: 'airpayms_status_failed',
};
exports.getTransactionStatusStyle = {
    [WalletTransactionStatus.Processing]: '#ffbb00',
    [WalletTransactionStatus.Success]: '#44cc77',
    [WalletTransactionStatus.Failed]: '#ee2c4a',
};
/** Transfer 【同 transaction】*/
// // 转账状态
// export enum WalletTransferStatus {
//   Processing = 2,
//   Success = 3,
//   Failed = 4,
// }
// // 交易状态 对应的国际化
// export const walletTransferStatusMapText: {
//   [key in WalletTransferStatus]: LangKey;
// } = {
//   [WalletTransferStatus.Processing]: 'airpayms_status_processing',
//   [WalletTransferStatus.Success]: 'airpayms_status_successful',
//   [WalletTransferStatus.Failed]: 'airpayms_status_failed',
// };
/** Batch Transfer */
var BatchStatus;
(function (BatchStatus) {
    BatchStatus[BatchStatus["PROCESSING"] = 1] = "PROCESSING";
    BatchStatus[BatchStatus["SUCCESS"] = 2] = "SUCCESS";
    BatchStatus[BatchStatus["FAILED"] = 3] = "FAILED";
    BatchStatus[BatchStatus["PARTIAL_SUCC"] = 4] = "PARTIAL_SUCC";
})(BatchStatus = exports.BatchStatus || (exports.BatchStatus = {}));
exports.getBatchStatusText = {
    [BatchStatus.PROCESSING]: 'airpayms_status_processing',
    [BatchStatus.SUCCESS]: 'airpayms_status_successful',
    [BatchStatus.FAILED]: 'airpayms_status_failed',
    [BatchStatus.PARTIAL_SUCC]: 'airpayms_status_patrial_success',
};
exports.getBatchStatusStyle = {
    [BatchStatus.PROCESSING]: '#ffbb00',
    [BatchStatus.SUCCESS]: '#44cc77',
    [BatchStatus.FAILED]: '#ee2c4a',
    [BatchStatus.PARTIAL_SUCC]: '#000000a6',
};
