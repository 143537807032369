import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { connect } from 'react-redux';
import Store from '../types/store';
import { jumpLoginPage } from '../utils/helper';
import { appRegion } from '../utils/constants';

type Props = Pick<Store.State, 'account'> & RouteProps;

const notLoginNeededPage = ['/login', '/changePassword'];

const PrivateRoute = ({
  component: Component,
  account: { staff_id, language },
  ...rest
}: Props) => (
  <Route
    {...rest}
    render={props => {
      const isLogin = !!staff_id;
      const path = props.match.path;
      const pathname = window.location.pathname;

      /**
       * 全部走 tob
       */
      // 未登陆跳转到登陆页
      if (!isLogin && !notLoginNeededPage.includes(props.location.pathname)) {
        // globalThis.sessionStorage.setItem('callback_url', path);
        jumpLoginPage(language);
      }
      if (pathname === '/api/auth_callback') {
        return (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location },
            }}
          />
        );
      }

      return Component && <Component {...props} />;
    }}
  />
);

export default connect(({ account }: Store.State) => ({ account }))(
  PrivateRoute
);
