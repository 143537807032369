"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// FIXME: 啰嗦，看下有没更好的办法
var PERMISSIONS;
(function (PERMISSIONS) {
    PERMISSIONS["TRANSACTION.PURCHASE.VIEW"] = "transaction.purchase.view";
    PERMISSIONS["TRANSACTION.PURCHASE.DOWNLOAD"] = "transaction.purchase.download";
    PERMISSIONS["TRANSACTION.PURCHASE.REFUND"] = "transaction.purchase.refund";
    PERMISSIONS["TRANSACTION.VOUCHER.VIEW"] = "transaction.voucher.view";
    PERMISSIONS["TRANSACTION.VOUCHER.DOWNLOAD"] = "transaction.voucher.download";
    PERMISSIONS["SETTLEMENT.VIEW"] = "settlement.view";
    PERMISSIONS["SETTLEMENT.DOWNLOAD"] = "settlement.download";
    PERMISSIONS["TAXINVOICE.VIEW"] = "taxinvoice.view";
    PERMISSIONS["TAXINVOICE.DOWNLOAD"] = "taxinvoice.download";
    PERMISSIONS["TRANSACTION.VOUCHER.SETTLEMENT_AMOUNT.VIEW"] = "transaction.voucher.settlement_amount.view";
    PERMISSIONS["WALLET.TRANSACTION_HISTORY.WALLET_BALANCE.VIEW"] = "wallet.transaction_history.wallet_balance.view";
    PERMISSIONS["WALLET.TRANSACTION_HISTORY.TRANSACTION_HISTORY.VIEW"] = "wallet.transaction_history.transaction_history.view";
    PERMISSIONS["WALLET.TRANSACTION_HISTORY.TRANSACTION_HISTORY.DOWNLOAD"] = "wallet.transaction_history.transaction_history.download";
    PERMISSIONS["WALLET.BATCH_TRANSFER.TRANSFER"] = "wallet.batch_transfer.transfer";
    PERMISSIONS["WALLET.BATCH_TRANSFER.BATCH_TRANSFER_LOG.VIEW"] = "wallet.batch_transfer.batch_transfer_log.view";
    PERMISSIONS["WALLET.BATCH_TRANSFER.TRANSFER_HISTORY.VIEW"] = "wallet.batch_transfer.transfer_history.view";
    PERMISSIONS["WALLET.BATCH_TRANSFER.TRANSFER_HISTORY.DOWNLOAD"] = "wallet.batch_transfer.transfer_history.download";
})(PERMISSIONS = exports.PERMISSIONS || (exports.PERMISSIONS = {}));
var PushNotifyStatus;
(function (PushNotifyStatus) {
    PushNotifyStatus[PushNotifyStatus["push"] = 1] = "push";
    PushNotifyStatus[PushNotifyStatus["noPush"] = 2] = "noPush";
})(PushNotifyStatus = exports.PushNotifyStatus || (exports.PushNotifyStatus = {}));
var TxnOrderStatus;
(function (TxnOrderStatus) {
    TxnOrderStatus[TxnOrderStatus["REFUNDED"] = 19] = "REFUNDED";
})(TxnOrderStatus = exports.TxnOrderStatus || (exports.TxnOrderStatus = {}));
